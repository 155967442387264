import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import CircleCheckbox from "../../../../components/CircleCheckBox";
import StatusCard from "../../../../components/StatusCard";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";

const Leads: React.FC = () => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleChange = (value: number) => {
    setPage(value);
  };

  const handleChange2 = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (id: number) => {
    setSelectedId(id);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "telefone", headerName: "Telefone", flex: 1 },
    {
      field: "parceiro",
      headerName: "Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpen()}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = [
    {
      id: 9594,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
    {
      id: 9595,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 1,
      outras: "ver mais",
    },
    {
      id: 9596,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 2,
      outras: "ver mais",
    },
    {
      id: 9597,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 3,
      outras: "ver mais",
    },
    {
      id: 9598,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
    {
      id: 9599,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 1,
      outras: "ver mais",
    },
    {
      id: 9590,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 2,
      outras: "ver mais",
    },
    {
      id: 9591,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 3,
      outras: "ver mais",
    },
    {
      id: 9592,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
    {
      id: 9593,
      nome: "José Luiz Almeida",
      telefone: "(37) 984563123",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
  ];

  const columnsModal: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "responsavel", headerName: "Responsavel", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data", flex: 1 },
  ];

  const rowsModal = [
    {
      id: 1,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 2,
      responsavel: "Rebecca de Assis",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 3,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 4,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 1,
      data: "05/11/2013",
    },
    {
      id: 5,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 1,
      data: "05/11/2013",
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard color="blue" text="Leads" number={254} />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="yellow"
                text="Aguardando envio de conta"
                number={56}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="green" text="Conta Anexada" number={103} />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="red" text="Cancelado" number={39} />
            </C.StatusWrapper>
          </C.StatusArea>
        )}
        <D.DataArea $align="right">
          <D.DefaultSearchInput
            size="small"
            variant="outlined"
            onChange={() => null}
            placeholder="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {!isMobile ? (
            <></>
          ) : (
            // <Datatable
            //   columns={columns}
            //   rows={rows}
            //   pageNumber={page}
            //   onChangePage={(e) => handleChange(e)}
            //   totalPages={10}
            // />
            <>
              <C.CardDataAreaMobile>
                {rows.map((row) => (
                  <C.CardDataMobile key={row.id}>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Id:</label>
                        <p>{row.id}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile></C.CardDataItemMobile>
                      <C.CardDataItemMobile $center={true}>
                        <label>Status:</label>
                        <D.Circle color={"blue"} />
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Nome:</label>
                        <p>{row.nome}</p>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Parceiro:</label>
                        <p>{row.parceiro}</p>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Telefone:</label>
                        <p>{row.telefone}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile>
                        <label>Data:</label>
                        <p>{row.data}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile $center={true}>
                        <D.DefaultGridButton onClick={() => handleOpen()}>
                          Ver mais
                        </D.DefaultGridButton>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                  </C.CardDataMobile>
                ))}
              </C.CardDataAreaMobile>
              <D.DefaultPagination
                count={10}
                size={"small"}
                siblingCount={0}
                showFirstButton
                showLastButton
                onChange={handleChange2}
                color="primary"
              />
            </>
          )}
          <C.LineBreak />
          <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status de leads</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Paulo Machado - 9591</h2>
                <C.CloseButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <h4>Status atual:</h4>
              <C.ModalStatusArea>
                <CircleCheckbox
                  id={0}
                  label="Leads"
                  selected={selectedId === 0}
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={1}
                  label="Aguardando envio de conta"
                  selected={selectedId === 1}
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={2}
                  label="Conta anexada"
                  selected={selectedId === 2}
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={3}
                  label="Cancelada"
                  selected={selectedId === 3}
                  onSelect={handleSelect}
                />
              </C.ModalStatusArea>
              <C.ModalInputsArea>
                <C.ModalInput
                  id="outlined-read-only-input"
                  label="Nome"
                  defaultValue="João Pé de Feijão"
                />
                <C.ModalInput
                  id="outlined-read-only-input"
                  label="Telefone"
                  defaultValue="(31) 99878-1267"
                />
                <C.ModalInput
                  id="outlined-read-only-input"
                  label="Email"
                  defaultValue="joaopedefeijao@gmail.com"
                />
                <C.ModalInput
                  id="outlined-read-only-input"
                  label="Parceiro"
                  defaultValue="Tramonte"
                />
                <C.ModalInput
                  id="outlined-read-only-input"
                  label="Backoffice"
                  defaultValue="Rebeca"
                />
              </C.ModalInputsArea>
              <C.ModalButtonsArea>
                <C.ModalDocumentButton
                  variant="contained"
                  startIcon={<PublishIcon />}
                >
                  Anexar Documento
                </C.ModalDocumentButton>
                <C.ModalHistoricButton variant="contained">
                  Incluir ao histórico
                </C.ModalHistoricButton>
                <C.ModalProposalButton variant="contained">
                  Gerar proposta
                </C.ModalProposalButton>
                <C.ModalSaveButton variant="contained">
                  Salvar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <C.ModalCard>
              CRM
              {/* <Datatable
                columns={columnsModal}
                rows={rowsModal}
                pageNumber={1}
                totalPages={5}
                onChangePage={() => {}}
              /> */}
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default Leads;
