import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as G from "../../style";
import { Checkbox, Chip, MenuItem, Stack, TextField } from "@mui/material";
import { ptBR } from "@mui/x-data-grid/locales";
import * as D from "../../../../../../../styles/appComponents";
import { GridColDef } from "@mui/x-data-grid";

const Ferias: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "periodocompentencia",
      headerName: "Período de competência",
      flex: 1,
    },
    {
      field: "aquisitivoinicio",
      headerName: "Aquisitivo início",
      flex: 1,
    },
    {
      field: "aquisitivofim",
      headerName: "Aquisitivo fim",
      flex: 1,
    },
    {
      field: "gozoinicio",
      headerName: "Gozo início",
      flex: 1,
    },
    {
      field: "gozofim",
      headerName: "Gozo fim",
      flex: 1,
    },
    {
      field: "diasgozo",
      headerName: "Dias de gozo",
      flex: 1,
      align: "center",
    },
    {
      field: "solicitacaoabono",
      headerName: "Solicitação abono",
      flex: 1,
    },
    {
      field: "abonoinicio",
      headerName: "Abono início",
      flex: 1,
    },
    {
      field: "abonofim",
      headerName: "Abono fim",
      flex: 1,
    },
    {
      field: "diasabono",
      headerName: "Dias de abono",
      flex: 1,
      align: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      periodocompentencia: "00/00/0000",
      aquisitivoinicio: "00/00/0000",
      aquisitivofim: "00/00/0000",
      gozoinicio: "00/00/0000",
      gozofim: "00/00/0000",
      diasgozo: "0",
      solicitacaoabono: "00/00/0000",
      abonoinicio: "00/00/0000",
      abonofim: "00/00/0000",
      diasabono: "0",
    },
    {
      id: 2,
      periodocompentencia: "00/00/0000",
      aquisitivoinicio: "00/00/0000",
      aquisitivofim: "00/00/0000",
      gozoinicio: "00/00/0000",
      gozofim: "00/00/0000",
      diasgozo: "0",
      solicitacaoabono: "00/00/0000",
      abonoinicio: "00/00/0000",
      abonofim: "00/00/0000",
      diasabono: "0",
    },
    {
      id: 3,
      periodocompentencia: "00/00/0000",
      aquisitivoinicio: "00/00/0000",
      aquisitivofim: "00/00/0000",
      gozoinicio: "00/00/0000",
      gozofim: "00/00/0000",
      diasgozo: "0",
      solicitacaoabono: "00/00/0000",
      abonoinicio: "00/00/0000",
      abonofim: "00/00/0000",
      diasabono: "0",
    },
    {
      id: 4,
      periodocompentencia: "00/00/0000",
      aquisitivoinicio: "00/00/0000",
      aquisitivofim: "00/00/0000",
      gozoinicio: "00/00/0000",
      gozofim: "00/00/0000",
      diasgozo: "0",
      solicitacaoabono: "00/00/0000",
      abonoinicio: "00/00/0000",
      abonofim: "00/00/0000",
      diasabono: "0",
    },
    {
      id: 5,
      periodocompentencia: "00/00/0000",
      aquisitivoinicio: "00/00/0000",
      aquisitivofim: "00/00/0000",
      gozoinicio: "00/00/0000",
      gozofim: "00/00/0000",
      diasgozo: "0",
      solicitacaoabono: "00/00/0000",
      abonoinicio: "00/00/0000",
      abonofim: "00/00/0000",
      diasabono: "0",
    },
    {
      id: 6,
      periodocompentencia: "00/00/0000",
      aquisitivoinicio: "00/00/0000",
      aquisitivofim: "00/00/0000",
      gozoinicio: "00/00/0000",
      gozofim: "00/00/0000",
      diasgozo: "0",
      solicitacaoabono: "00/00/0000",
      abonoinicio: "00/00/0000",
      abonofim: "00/00/0000",
      diasabono: "0",
    },
  ];
  return (
    <RegisterCard title="Férias">
      <D.DefaultTable
        disableColumnResize
        disableRowSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
      />
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton>Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default Ferias;
