import { Avatar, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SidebarMenu from "../../components/SidebarMenu";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { ISidebarMenu } from "../../models/SidebarPopulate";
import ParceiroRoutes from "./parceiroRoutes/parceiroRoutes";
import * as C from "./style";

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../hooks/useAuth";

const ParceiroLayout: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate("/Parceiro");
    }

    setLoading(false);
  }, [user]);

  const [sidebarXOpen, setSidebarXOpen] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [avatarOpen, setAvatarOpen] = useState(false);
  const [avatarOpenMobile, setAvatarOpenMobile] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string>("");

  const location = useLocation();

  const avatarButtonRef = useRef(null);
  const avatarRef = useOutsideClick(() => {
    setAvatarOpen(false);
  }, [avatarButtonRef]);

  const helpButtonRef = useRef(null);
  const helpRef = useOutsideClick(() => {
    setHelpOpen(false);
  }, [helpButtonRef]);

  const notificationButtonRef = useRef(null);
  const notificationRef = useOutsideClick(() => {
    setNotificationOpen(false);
  }, [notificationButtonRef]);

  const sidebarMobileButtonRef = useRef(null);
  const sidebarMobileRef = useOutsideClick(() => {
    setSidebarOpen(false);
  }, [sidebarMobileButtonRef]);

  const handleAvatarOpen = () => {
    setAvatarOpen(!avatarOpen);
  };

  const handleAvatarOpenMobile = () => {
    setAvatarOpenMobile(!avatarOpenMobile);
  };

  const handleHelpOpen = () => {
    setHelpOpen(!helpOpen);
  };

  const handleNotificationOpen = () => {
    setNotificationOpen(!notificationOpen);
  };

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSelectedItem = (item: string) => {
    if (selectedItem === item) {
      setSelectedItem("");
    } else {
      setSelectedItem(item);
    }
  };

  const nameInicials = (nome: string | undefined) => {
    if (nome == undefined) {
      return "";
    }

    const nomes = nome.split(" ");
    let inicial = "";
    let final = "";

    if (nomes.length > 0) {
      inicial = nomes[0].charAt(0);
    }

    if (nomes.length > 0 && nomes[nomes.length - 1]) {
      final = nomes[nomes.length - 1].charAt(0);
    }

    return `${inicial}${final}`;
  };

  return loading ? (
    <C.Container>
      <C.LoadingWrapper>
        <CircularProgress size={100} />
      </C.LoadingWrapper>
    </C.Container>
  ) : (
    <>
      <C.Container>
        <C.Navbar>
          <C.HamburguerButton
            ref={sidebarMobileButtonRef}
            onClick={() => handleSidebarOpen()}
          >
            <MenuIcon sx={{ fontSize: 30 }} />
          </C.HamburguerButton>
          <h2>
            {location.pathname.replace("/Parceiro/", "").replaceAll("/", " > ")}
          </h2>
          <C.Right>
            {/* <C.DropdownContainer>
              <C.NavBadges
                ref={notificationButtonRef}
                onClick={() => handleNotificationOpen()}
              >
                <Badge badgeContent={5} color="success">
                  <NotificationsNoneRoundedIcon />
                </Badge>
              </C.NavBadges>
              {notificationOpen && (
                <C.NotificationOpen ref={notificationRef}>
                  <C.Notification>
                    <h3>Notificações</h3>
                  </C.Notification>
                  <C.Notification>
                    <C.NotificationLeft>
                      <p>
                        Luiza Parente Souza saiu do status aguardando envio de
                        conta para conta anexada
                      </p>
                      <span>Há 2 horas</span>
                    </C.NotificationLeft>
                    <C.NotificationRight>
                      <C.Circle color={0} />
                    </C.NotificationRight>
                  </C.Notification>
                  <C.Notification>
                    <C.NotificationLeft>
                      <p>Quatro novas leads</p>
                      <span>Há 2 horas</span>
                    </C.NotificationLeft>
                    <C.NotificationRight>
                      <C.Circle color={2} />
                    </C.NotificationRight>
                  </C.Notification>
                </C.NotificationOpen>
              )}
            </C.DropdownContainer>

            <C.DropdownContainer
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <C.NavBadges ref={helpButtonRef} onClick={() => handleHelpOpen()}>
                <Badge>
                  <HelpOutlineRoundedIcon />
                </Badge>
              </C.NavBadges>
              {helpOpen && (
                <C.DropdownOpen ref={helpRef}>
                  <h3>Seu backoffice</h3>
                  <Avatar sx={{ width: 56, height: 56 }}>RA</Avatar>
                  <C.NameWrapper>
                    <h4>Rebeca Assis</h4>
                    <p>(37) 984563123</p>
                  </C.NameWrapper>
                  <C.WppButton variant="contained" startIcon={<WhatsAppIcon />}>
                    Contate-nos
                  </C.WppButton>
                </C.DropdownOpen>
              )}
            </C.DropdownContainer> */}

            <C.DropdownContainer>
              <C.AvatarWrapper
                ref={avatarButtonRef}
                onClick={() => handleAvatarOpen()}
              >
                <Avatar>{nameInicials(user?.nome)}</Avatar>
                <C.NameWrapper>
                  <h4>{user?.nome}</h4>
                  <p>{user?.emailLogin}</p>
                </C.NameWrapper>
                <KeyboardArrowDownRoundedIcon />
              </C.AvatarWrapper>
              {avatarOpen && (
                <C.DropdownOpen ref={avatarRef}>
                  <Avatar sx={{ width: 56, height: 56 }}>
                    {nameInicials(user?.nome)}
                  </Avatar>
                  <C.NameWrapper>
                    <h4>{user?.nome}</h4>
                    <p>{user?.emailLogin}</p>
                  </C.NameWrapper>
                  <C.AvatarLine />
                  <C.AvatarLinksWrapper>
                    <C.AvatarLinks to="/Parceiro/Dashboard">
                      Ver meu perfil
                    </C.AvatarLinks>
                    <C.AvatarLinks to="/Parceiro/Dashboard">
                      Link do parceiro
                    </C.AvatarLinks>
                    <C.AvatarLinksButton onClick={() => logout()}>
                      Sair
                    </C.AvatarLinksButton>
                  </C.AvatarLinksWrapper>
                </C.DropdownOpen>
              )}
            </C.DropdownContainer>
          </C.Right>
        </C.Navbar>
        <C.Wrapper>
          {(avatarOpen || helpOpen || notificationOpen) && <C.WrapperBlock />}
          <C.SideBar $xOpen={sidebarXOpen}>
            <C.IconButtonWrapper $xOpen={sidebarXOpen}>
              <C.StyledIconButton
                onClick={() => setSidebarXOpen(!sidebarXOpen)}
              >
                {sidebarXOpen ? (
                  <ArrowBackIosRoundedIcon />
                ) : (
                  <ArrowForwardIosRoundedIcon />
                )}
              </C.StyledIconButton>
            </C.IconButtonWrapper>
            {ParceiroRoutes().map((item: ISidebarMenu) => (
              <SidebarMenu
                xOpen={sidebarXOpen}
                key={item.title}
                open={item.title === selectedItem}
                sidebarMenuItem={item}
                onDataReceived={handleSelectedItem}
              />
            ))}
          </C.SideBar>

          <C.Content $xOpen={sidebarXOpen}>
            <Outlet />
          </C.Content>
        </C.Wrapper>
      </C.Container>
      {sidebarOpen && (
        <>
          <C.WrapperBlock />
          <C.SideBarMobile ref={sidebarMobileRef}>
            <C.CloseButtonArea>
              <C.HamburguerButton onClick={() => handleSidebarOpen()}>
                <ClearIcon />
              </C.HamburguerButton>
            </C.CloseButtonArea>
            <C.AvatarSideBarWrapperMobile>
              <Avatar sx={{ width: 56, height: 56 }}>
                {nameInicials(user?.nome)}
              </Avatar>
              <C.AvatarNameMobile onClick={() => handleAvatarOpenMobile()}>
                <C.NameWrapper>
                  <h4>{user?.nome}</h4>
                  <p>{user?.emailLogin}</p>
                </C.NameWrapper>
                {!avatarOpenMobile ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </C.AvatarNameMobile>
              {avatarOpenMobile && (
                <C.AvatarLinksWrapper>
                  <C.AvatarLinks to="/Parceiro/Leads">
                    Ver meu perfil
                  </C.AvatarLinks>
                  <C.AvatarLinks to="/Parceiro/Leads">
                    Link do parceiro
                  </C.AvatarLinks>
                  <C.AvatarLinksButton onClick={() => logout()}>
                    Sair
                  </C.AvatarLinksButton>
                </C.AvatarLinksWrapper>
              )}
            </C.AvatarSideBarWrapperMobile>
            <C.SidebarMobileLine />
            <C.SideBarMobileLinksArea>
              {ParceiroRoutes().map((item: ISidebarMenu) => (
                <SidebarMenu
                  xOpen={true}
                  key={item.title}
                  open={item.title === selectedItem}
                  sidebarMenuItem={item}
                  onDataReceived={handleSelectedItem}
                />
              ))}
            </C.SideBarMobileLinksArea>
          </C.SideBarMobile>
        </>
      )}
    </>
  );
};

export default ParceiroLayout;
