import styled from "styled-components";
import thumbnail from "../../../assets/LandingPage/thumbnail.png";
import { ReactComponent as Logo } from "../../../assets/LandingPage/playButton.svg";
import { IconButton, Modal } from "@mui/material";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: scroll;
  font-family: "Inter", sans-serif;

  img {
    max-width: 100%;
  }
`;

export const BannerArea = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  padding: 70px 150px;

  @media (max-width: 768px) {
    padding: 50px 30px;
  }
`;

export const CardBenefitsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

export const VideoArea = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  width: 50%;

  cursor: pointer;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const Right = styled.div`
  width: 50%;

  h2 {
    color: #f36a7b;
    font-size: 50px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    margin: 0;

    @media (max-width: 1024px) {
      font-size: 25px;
      line-height: 30.26pxpx;
    }
  }

  p {
    color: #333333;
    font-size: 22px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 26.5px;

    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 16.86px;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Video = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  background-color: red;
  background: url(${thumbnail});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayButton = styled(Logo)`
  color: #f36a7b;
  cursor: pointer;

  &:hover {
    color: #cc5261;
  }
`;

export const VideoModal = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VideoModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  position: relative;
  video {
    width: 100%;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  margin: 10px;
  top: 0;
  right: 0;
  z-index: 99999999;
`;
