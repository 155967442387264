import React from "react";
import Login from "../../../../components/Login";
import colaborador from "../../../../assets/Login/imgColaborador.svg";
import * as C from "../../../../components/Login/style";
import { useNavigate } from "react-router-dom";

const topics: string[] = [
  "Você é a engrenagem vital desta empresa. Obrigado por ser parte fundamental de nosso sucesso.",
  "Sua dedicação e compromisso são essenciais para o crescimento e sucesso da empresa.",
  "Sua presença é mais do que apenas uma entrada no sistema. Você é a força motriz por trás de nossa empresa. Obrigado por tudo o que você faz!",
];

const RecuperarSenhaColaborador: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Login
        title="colaborador"
        topics={topics}
        imageSrc={colaborador}
        formTitle="Recuperar Senha"
        formSubtitle="Esqueceu sua senha? digite seu e-mail que enviaremos um link para definir uma nova senha"
      >
        <C.LoginForm>
          <C.LoginInput label="Login" variant="outlined" />
          <C.LoginInput label="E-mail" variant="outlined" type="email" />
          <C.LoginButton
            variant="contained"
            onClick={() => navigate("/Login/Colaborador")}
          >
            Recuperar
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default RecuperarSenhaColaborador;
