import { Chip, TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "./style";
import * as D from "../../../../../styles/appComponents";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import DownloadIcon from "@mui/icons-material/Download";
import { ptBR } from "@mui/x-data-grid/locales";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const CadastroGerador = () => {
  const [extraContacts, setExtraContacts] = useState(1);
  const [extraResponsable, setExtraResponsable] = useState(1);
  const [extraTarifas, setExtraTarifas] = useState(1);

  const columns: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    { field: "desc", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data", flex: 1 },
  ];

  const rows = [
    {
      id: 1,
      responsavel: "Rebeca de Assis",
      desc: "Anexou conta",
      status: 2,
      data: "03/11/2023",
    },
    {
      id: 2,
      responsavel: "Rebeca de Assis",
      desc: "Anexou conta",
      status: 2,
      data: "03/11/2023",
    },
    {
      id: 3,
      responsavel: "Rebeca de Assis",
      desc: "Anexou conta",
      status: 2,
      data: "03/11/2023",
    },
    {
      id: 4,
      responsavel: "Rebeca de Assis",
      desc: "Anexou conta",
      status: 2,
      data: "03/11/2023",
    },
    {
      id: 5,
      responsavel: "Rebeca de Assis",
      desc: "Anexou conta",
      status: 1,
      data: "03/11/2023",
    },
    {
      id: 6,
      responsavel: "Rebeca de Assis",
      desc: "Anexou conta",
      status: 1,
      data: "03/11/2023",
    },
  ];

  const columns2: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    { field: "desc", headerName: "Decrição", flex: 1 },
    {
      field: "data",
      headerName: "Data Upload",
      flex: 1,
    },
    {
      field: "anexo",
      headerName: "Anexo",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DownloadIcon />}
            $color="blue"
          >
            Baixar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DeleteIcon />}
            $color="red"
          >
            Deletar
          </D.OutlineButton>
        </D.GridField>
      ),
    },
  ];

  const rows2 = [
    {
      id: 1,
      responsavel: "Parceiro",
      desc: "Logo SVG",
      data: "01/01/2024",
    },
    {
      id: 2,
      responsavel: "Parceiro",
      desc: "Logo PNG",
      data: "01/01/2024",
    },
    {
      id: 3,
      responsavel: "Parceiro",
      desc: "Logo PDF",
      data: "01/01/2024",
    },
  ];

  return (
    <>
      <RegisterCard title="Dados da usina">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Razão social/Nome" required fullWidth />
          <TextField label="CNPJ/CPF" required fullWidth />
          <TextField label="Instalação" required sx={{ width: "50%" }} />
          <TextField label="Sigla" required sx={{ width: "30%" }} />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="CEP" required fullWidth />
          <TextField label="UF" required sx={{ width: "30%" }} />
          <TextField label="Cidade" required fullWidth />
          <TextField label="Bairro" required fullWidth />
          <TextField label="Logradouro" required fullWidth />
          <TextField label="Número" required sx={{ width: "40%" }} />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Complemento" required sx={{ width: "50%" }} />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Informações do responsavel legal">
        {Array.from({ length: extraResponsable }).map((_, index) => (
          <>
            <C.FWStack
              direction={"row"}
              spacing={2}
              key={index}
              marginTop={index > 0 ? "30px" : "none"}
            >
              <TextField label="Nome do responsável legal" required fullWidth />
              <TextField label="E-mail" required fullWidth />
              <TextField label="Celular" required fullWidth />
              <TextField label="CPF" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2} key={index}>
              <TextField label="CEP" required fullWidth />
              <TextField label="UF" required sx={{ width: "40%" }} />
              <TextField label="Cidade" required fullWidth />
              <TextField label="CPF" required fullWidth />
              <TextField label="Bairro" required fullWidth />
              <TextField label="Número" required sx={{ width: "40%" }} />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2} key={index}>
              <TextField label="Complemento" required fullWidth />
              <TextField label="Logradouro" required fullWidth />
            </C.FWStack>
          </>
        ))}
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            startIcon={<AddIcon />}
            onClick={() => setExtraResponsable(extraResponsable + 1)}
            $color="lightpink"
          >
            Adicionar outra forma de contato
          </D.ContainedButton>
        </C.FWStack>
        <C.TitleArea>
          <h2>Responsável pela comunicação</h2>
        </C.TitleArea>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Nome" required fullWidth />
          <TextField label="Email" required fullWidth />
          <TextField label="Celular" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Informações do contrato">
        {Array.from({ length: extraContacts }).map((_, index) => (
          <>
            <C.FWStack
              direction={"row"}
              spacing={2}
              marginTop={index > 0 ? "30px" : "none"}
            >
              <TextField
                label="Tipo de pessoa"
                required
                select
                sx={{ width: "80%" }}
              />
              <TextField
                label="Operadora"
                required
                select
                sx={{ width: "80%" }}
              />
              <TextField
                label="Tipo de tarifa"
                required
                select
                sx={{ width: "80%" }}
              />
              <TextField
                label="Porcentagem de desconto da tarifa"
                required
                fullWidth
              />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField
                label="Tarifa vigente"
                required
                sx={{ width: "60%" }}
              />
              <TextField
                label="Tarifa do gerador"
                required
                sx={{ width: "70%" }}
              />
              <TextField
                label="Potência"
                required
                select
                sx={{ width: "80%" }}
              />
              <TextField
                label="Potência por extenso"
                required
                sx={{ width: "80%" }}
              />

              <TextField
                label="Medida corrente"
                required
                sx={{ width: "80%" }}
              />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField
                label="Performance alvo"
                required
                sx={{ width: "60%" }}
              />
              <TextField label="Custo fixo" required sx={{ width: "70%" }} />
              <TextField label="Custo fixo por extenso" required fullWidth />
              <TextField label="Vigência contratual" required fullWidth />
              <TextField label="Data limite de conexão" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField
                label="Tipo de conexão"
                required
                sx={{ width: "60%" }}
              />
              <TextField
                label="Status do contrato gerador"
                required
                sx={{ width: "70%" }}
              />
              <TextField
                label="Data de assinatura do contrato"
                required
                fullWidth
              />
              <TextField label="UUID do contrato" required fullWidth />
            </C.FWStack>
          </>
        ))}
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            startIcon={<AddIcon />}
            onClick={() => setExtraContacts(extraContacts + 1)}
            $color="lightpink"
          >
            Adicionar indicação
          </D.ContainedButton>
        </C.FWStack>
        <C.TitleArea>
          <h2>Responsável pela indicação</h2>
        </C.TitleArea>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Tem comissão" required fullWidth />
          <TextField label="Porcentual de comissão" required fullWidth />
          <TextField label="Nome do parceiro" required fullWidth />
          <TextField label="Celular do parceiro" required fullWidth />
          <TextField label="Email do parceiro" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={16}>
          <C.MWDiv>
            <C.Subtitle>Dados bancários*</C.Subtitle>
            <C.Text>Insira seus dados bancários</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Agência" required fullWidth />
              <TextField label="Conta" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Número" required fullWidth />
              <TextField label="Banco" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="CNPJ do banco" required fullWidth />
              <C.FWDiv></C.FWDiv>
            </C.FWStack>
          </C.MWDiv>
          <C.MWDiv>
            <C.Subtitle>Pix*</C.Subtitle>
            <C.Text>Insira sua chave pix</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Chave PIX" required fullWidth />
            </C.FWStack>
            <C.Text>Qual dado você quer usar?</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <Chip label="CPF" />
              <Chip label="CNPJ" />
              <Chip label="Email" />
              <Chip label="Celular" />
              <Chip label="Aleatória" />
            </C.FWStack>
          </C.MWDiv>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Dados para pagamento do gerador">
        {Array.from({ length: extraTarifas }).map((_, index) => (
          <C.FWStack direction={"row"} spacing={2}>
            <TextField label="Tipo de conexão" select required fullWidth />
            <TextField
              label="Desconto de tarifa percentual"
              required
              fullWidth
            />
            <TextField label="Tarifa vigente" required fullWidth />
            <TextField label="Tarifa do gerador" required fullWidth />
            <TextField label="Data inicial" required fullWidth />
            <TextField label="Data final" required fullWidth />
          </C.FWStack>
        ))}
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            startIcon={<AddIcon />}
            onClick={() => setExtraTarifas(extraTarifas + 1)}
            $color="pink"
          >
            Nova tarifa
          </D.ContainedButton>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={16}>
          <C.MWDiv>
            <C.Subtitle>Dados bancários*</C.Subtitle>
            <C.Text>Insira seus dados bancários</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Agência" required fullWidth />
              <TextField label="Conta" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Número" required fullWidth />
              <TextField label="Banco" required fullWidth />
            </C.FWStack>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="CNPJ do banco" required fullWidth />
              <C.FWDiv></C.FWDiv>
            </C.FWStack>
          </C.MWDiv>
          <C.MWDiv>
            <C.Subtitle>Pix*</C.Subtitle>
            <C.Text>Insira sua chave pix</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <TextField label="Chave PIX" required fullWidth />
            </C.FWStack>
            <C.Text>Qual dado você quer usar?</C.Text>
            <C.FWStack direction={"row"} spacing={2}>
              <Chip label="CPF" />
              <Chip label="CNPJ" />
              <Chip label="Email" />
              <Chip label="Celular" />
              <Chip label="Aleatória" />
            </C.FWStack>
          </C.MWDiv>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="Anexos de documentos">
        <C.FWStack direction={"row"} spacing={8}>
          <C.MWDiv>
            <C.ArchiveArea>
              <C.Title>
                <FileUploadIcon />
                <h2>Upload de arquivos</h2>
              </C.Title>
              <C.FWStack>
                <TextField
                  sx={{ backgroundColor: "white" }}
                  label="Descrição do documento"
                  multiline
                  rows={4}
                />
              </C.FWStack>
              <C.FWStack direction={"row"} justifyContent={"space-between"}>
                <D.ContainedButton $color="blue" startIcon={<FileUploadIcon />}>
                  Anexar documento
                </D.ContainedButton>
                <D.ContainedButton>Anexar</D.ContainedButton>
              </C.FWStack>
            </C.ArchiveArea>
          </C.MWDiv>
          <C.MWDiv>
            <C.LightBWArea>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows2}
                columns={columns2}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </C.LightBWArea>
          </C.MWDiv>
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
      <RegisterCard title="CRM">
        <D.DefaultTable
          disableColumnResize
          disableRowSelectionOnClick
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          hideFooter
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
        />
      </RegisterCard>
      <RegisterCard title="Dados de acesso">
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Login" required fullWidth />
          <TextField label="Senha" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
          <D.ContainedButton>Salvar</D.ContainedButton>
        </C.FWStack>
      </RegisterCard>
    </>
  );
};

export default CadastroGerador;
