import { TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as C from "../../style";
import { IParceiro } from "../../../../../../models/Parceiro";

interface IDadosContratoProps {
  parceiro: IParceiro;
}

const DadosContrato: React.FC<IDadosContratoProps> = ({ parceiro }) => {
  return (
    <RegisterCard title="Dados do contrato">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={parceiro.captador.empresas[0].razaoSocial}
          label="Razão social"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={parceiro.captador.empresas[0].documentos[0].numero}
          label="CNPJ"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={parceiro.captador.empresas[0].enderecos[0].cep}
          label="CEP"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={parceiro.captador.empresas[0].enderecos[0].uf}
          label="UF"
          required
          inputProps={{ readOnly: true }}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={parceiro.captador.empresas[0].enderecos[0].cidade}
          label="Cidade"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={parceiro.captador.empresas[0].enderecos[0].bairro}
          label="Bairro"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={parceiro.captador.empresas[0].enderecos[0].logradouro}
          label="Logradouro"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={parceiro.captador.empresas[0].enderecos[0].numero}
          label="Número"
          required
          sx={{ width: "40%" }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={parceiro.captador.empresas[0].enderecos[0].complemento}
          label="Complemento"
          required
          fullWidth
          inputProps={{ readOnly: true }}
        />
      </C.FWStack>
    </RegisterCard>
  );
};

export default DadosContrato;
