import * as C from "./style";
import gif from "../../../../../../assets/LandingPageParceiro/benefitsGif.gif";

const Benefits = () => {
  return (
    <C.Container>
      <C.Title>
        Vantagens de ser um parceiro <C.MarkedText>Watt Wise</C.MarkedText>
      </C.Title>
      <C.TextWrapper>
        <C.Left>
          <p>
            Nosso objetivo é estabelecer parcerias para expandir e fortalecer a
            rede de clientes que utilizam energia solar. Por isso, a{" "}
            <C.MarkedText>remuneração </C.MarkedText>
            dos parceiros da Watt Wise é uma das mais atrativas do mercado,
            oferecendo uma <C.MarkedText>comissão de 2% </C.MarkedText> sobre o
            valor da assinatura dos contratos. Essa comissão é paga mensalmente
            de forma recorrente enquanto o cliente mantiver o contrato ativo.
            Junte-se a nós e aproveite uma{" "}
            <C.MarkedText>parceria financeiramente recompensadora</C.MarkedText>
            , além de contribuir para promover a preservação do meio ambiente
            através de uma energia limpa, acessível e sustentável.
          </p>
        </C.Left>
        <C.Right>
          <img src={gif} alt="VantagensGif" />
        </C.Right>
      </C.TextWrapper>
      <C.AdjectivesWrapper>
        <C.Adjectives>
          <h4>Renda extra</h4>
          <p>
            Receita recorrente durante todo o període que o cliente permanecer
            conosco.
          </p>
        </C.Adjectives>
        <C.Adjectives>
          <h4>Transparência</h4>
          <p>
            Acompanhe seus clientes em todos os processos, desde a proposta até
            o boleto.
          </p>
        </C.Adjectives>
        <C.Adjectives>
          <h4>Suporte especializado</h4>
          <p>
            Conte com o apoio da nossa equipe para gestão de todo o processo pré
            e pós venda.
          </p>
        </C.Adjectives>
        <C.Adjectives>
          <h4>Segurança</h4>
          <p>
            Uma plataforma segura e intuitiva onde o parceiro pode acompanhar o
            fluxo de clientes e gerar propostas.
          </p>
        </C.Adjectives>
      </C.AdjectivesWrapper>
    </C.Container>
  );
};

export default Benefits;
