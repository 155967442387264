import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: scroll;
  font-family: "Inter", sans-serif;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 100%;
  padding: 70px 150px;

  @media (max-width: 768px) {
    padding: 50px 30px;
  }
`;
