import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: white;
  box-shadow: 6px 6px 6px #0000000f;
  border-radius: 10px;
  padding: 20px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 70%;
`;

export const Circle = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: ${({ color }) => {
    switch (color) {
      case "blue":
        return "blue";
      case "yellow":
        return "yellow";
      case "green":
        return "green";
      case "red":
        return "red";
      case "purple":
        return "purple";
      default:
        return "transparent";
    }
  }};
`;

export const Text = styled.p`
  max-width: calc(100% - 40px);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;
