import styled from "styled-components";

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f36a7b;
  padding: 30px 100px;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 30px;
    gap: 16px;
  }
`;

export const FooterLeft = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

export const FooterTitle = styled.h4`
  margin: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
`;

export const MediaWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #ffffff;

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }

  svg {
    font-size: 32px;
  }
`;

export const FooterRight = styled.div`
  width: 40%;
  display: flex;
  justify-content: end;

  img {
    width: 70%;
  }
`;
