import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import * as C from "./style";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface RegisterCardProps {
  title: string;
}

type RegisterCardPropsWithChildren = React.FC<
  React.PropsWithChildren<RegisterCardProps>
>;

const RegisterCard: RegisterCardPropsWithChildren = ({ children, title }) => {
  return (
    <C.Container>
      <C.Header>
        <h2>{title}</h2>
      </C.Header>
      <C.Content>{children}</C.Content>
    </C.Container>
  );
};

export default RegisterCard;
