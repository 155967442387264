import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 14px;

  border-left: 3px solid #f36a7b;

  h2 {
    margin: 0;
    font-weight: 500;
    font-size: 40px;
    color: #3e3e3e;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    color: #3e3e3e;
  }
`;
