import { IPaginatedList } from "../../models/PaginatedList";
import {
  IGDPropostaPaged,
  IStatusDashboardProposta,
} from "../../models/Proposta";
import http from "../common/http-common";

export const GetStats = () => {
  return http.get<IStatusDashboardProposta[]>(`/GDProposta/Stats`);
};

export const GetPropostas = (pageNumber: number, pageSize: number) => {
  return http.get<IPaginatedList<IGDPropostaPaged>>(
    `/GDProposta?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};
