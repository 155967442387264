import React, { useRef, useState } from "react";
import Navbar from "./components/Navbar";
import * as C from "./style";
import Footer from "./components/Footer";
import CardBenefits from "./components/CardBenefits";
import img1 from "../../../assets/LandingPage/benefits1.png";
import img2 from "../../../assets/LandingPage/benefits2.png";
import img3 from "../../../assets/LandingPage/benefits3.png";
import img4 from "../../../assets/LandingPage/benefits4.png";
import video from "../../../assets/LandingPage/video.mp4";
import ScrollTopButton from "../../../components/ScrollTopButton";
import CloseIcon from "@mui/icons-material/Close";
import bg from "../../../assets/LandingPage/homeBg.png";

const Home: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const wattwiseRef = useRef<HTMLInputElement | null>(null);
  const produtosRef = useRef<HTMLInputElement | null>(null);
  const contatoRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLInputElement | null>(null);

  const handleScroll = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: window.innerWidth < 1025 ? "start" : "center",
    });
  };

  return (
    <C.Container ref={containerRef}>
      <Navbar
        wattwise={wattwiseRef}
        produtos={produtosRef}
        contato={contatoRef}
        scroll={handleScroll}
      />
      <C.BannerArea>
        <img src={bg} alt="bannerWattwise" />
      </C.BannerArea>
      <C.Content>
        <C.CardBenefitsArea ref={wattwiseRef}>
          <CardBenefits
            title="Liberdade de consumo"
            description="Você pode consumir +/- 50% do seu volume contratual pagando o preço acordado, sem exposição a preços spots."
            imageSrc={img1}
          />
          <CardBenefits
            title="Zero risco de exposição ao PLD"
            description="Você não fica exposto ao preço horário de energia, assumimos esse risco e a modulação do contrato é conforme seu consumo."
            imageSrc={img2}
          />
          <CardBenefits
            title="Sem necessidade de garantia financeira"
            description="Mais agilidade e menos burocracia, você não precisa apresentar garantia financeira."
            imageSrc={img3}
          />
          <CardBenefits
            title="Mais tempo para realizar o pagamento"
            description="Você pode pagar suas faturas até o 6° dia útil de cada mês, é mais facilidade nos processos de pagamento e mais tempo para organizar o fluxo de caixa."
            imageSrc={img4}
          />
        </C.CardBenefitsArea>
        <C.VideoArea ref={produtosRef}>
          <C.Left>
            <C.Video onClick={handleOpen}>
              <C.PlayButton className="button" color="red" />
            </C.Video>
          </C.Left>
          <C.Right>
            <h2>Quem somos!</h2>
            <p>
              A Watt Wise surgiu para trazer soluções para o mercado de energia.
              Somos uma empresa que transforma problemas no setor de energia em
              soluções e resultados, contando com uma equipe altamente
              especializada. Estamos na vanguarda da inovação. Nossa missão é
              ser escolhida pelos nossos clientes pela excelência no atendimento
              e pelos nossos parceiros como a melhor plataforma de serviços no
              setor de energia do Brasil. Acreditamos que o sucesso da nossa
              atuação depende diretamente da qualidade das relações contínuas ao
              longo da nossa história e da abrangência do valor compartilhado
              junto ao cliente, parceiro, acionista e comunidade.
            </p>
          </C.Right>
        </C.VideoArea>
      </C.Content>
      <Footer contatoRef={contatoRef} />
      <C.VideoModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-video"
        aria-describedby="modal-video"
      >
        <C.VideoModalContent>
          <C.CloseButton onClick={() => handleClose()}>
            <CloseIcon />
          </C.CloseButton>
          <video controls>
            <source src={video} type="video/mp4" />
          </video>
        </C.VideoModalContent>
      </C.VideoModal>
      <ScrollTopButton container={containerRef} />
    </C.Container>
  );
};

export default Home;
