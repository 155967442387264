import React, { useRef, useState } from "react";
import * as C from "./style";
import upload from "../../assets/upload.png";
import Swal from "sweetalert2";

interface ImageInputProps {
  onImageChange: (image: File | null) => void;
}

const ImageInput: React.FC<ImageInputProps> = ({ onImageChange }) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const imageMimeType = /image\/(png|jpg|jpeg)/i;

      if (!file.type.match(imageMimeType)) {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title:
            "O arquivo selecionado não é uma imagem válida. Use imagens .png, .jpg e .jpeg",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = function () {
        setImageUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(file);
      onImageChange(file);
    }
  };

  return (
    <>
      <C.Container onClick={() => inputRef.current?.click()}>
        <img src={imageUrl || upload} alt="fotoColaborador" />
      </C.Container>
      <C.VisuallyHiddenInput
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleImageUpload}
      />
    </>
  );
};

export default ImageInput;
