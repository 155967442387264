import * as C from "./style";
import logo from "../../../../../../assets/logoWhite.png";
import { Stack } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const Footer = () => {
  return (
    <C.FooterWrapper>
      <C.FooterLeft>
        <Stack direction={"column"} spacing={2}>
          <C.FooterTitle>Nossos canais</C.FooterTitle>
          <Stack direction={"row"} spacing={8}>
            <C.MediaWrapper>
              <WhatsAppIcon />
              <p>(31) 97221-1075</p>
            </C.MediaWrapper>
            <C.MediaWrapper>
              <YouTubeIcon />
              <p>@wattwise.app</p>
            </C.MediaWrapper>
          </Stack>
          <Stack direction={"row"} spacing={8}>
            <C.MediaWrapper>
              <InstagramIcon />
              <p>wattwise.app</p>
            </C.MediaWrapper>
            <C.MediaWrapper>
              <EmailOutlinedIcon />
              <p>parcerias@wattwise.app.br</p>
            </C.MediaWrapper>
          </Stack>
        </Stack>
      </C.FooterLeft>
      <C.FooterRight>
        <img src={logo} alt="LogoWattWise" />
      </C.FooterRight>
    </C.FooterWrapper>
  );
};

export default Footer;
