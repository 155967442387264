import React, { useRef } from "react";
import Navbar from "./components/Navbar";
import * as C from "./style";
import ScrollTopButton from "../../../../components/ScrollTopButton";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Benefits from "./components/Benefits";
import BecomePartner from "./components/BecomePartner";
import Questions from "./components/Questions";

const ParceiroHome: React.FC = () => {
  const containerRef = useRef<HTMLInputElement | null>(null);

  return (
    <C.Container ref={containerRef}>
      <Navbar />
      <Banner />
      <C.Content>
        <Benefits />
        <BecomePartner />
        <Questions />
      </C.Content>
      <Footer />
      <ScrollTopButton container={containerRef} />
    </C.Container>
  );
};

export default ParceiroHome;
