import React, { useState } from "react";
import * as C from "./style";
import logo from "../../../../../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    setOpen(!open);
  };

  return (
    <>
      <C.NavbarWrapper>
        <C.Logo src={logo} alt="logoWattwise" />
        <C.LinksWrapperDesktop>
          <C.LinksButton $outlined onClick={() => navigate("/Login/Parceiro")}>
            Já sou parceiro
          </C.LinksButton>
          <C.LinksButton
            onClick={() => navigate("/Parceiro/CadastrarParceiro")}
          >
            Quero ser parceiro
          </C.LinksButton>
        </C.LinksWrapperDesktop>
        <C.HamburguerButton onClick={handleOpen}>
          {open ? (
            <ClearIcon sx={{ fontSize: 30 }} />
          ) : (
            <MenuIcon sx={{ fontSize: 30 }} />
          )}
        </C.HamburguerButton>
      </C.NavbarWrapper>
      {open && (
        <>
          <C.WrapperBlock />
          <C.LinksWrapperMobile>
            <C.LinksButton
              $outlined
              onClick={() => navigate("/Login/Parceiro")}
            >
              Já sou parceiro
            </C.LinksButton>
            <C.LinksButton onClick={() => navigate("/Login/Cliente")}>
              Quero ser parceiro
            </C.LinksButton>
          </C.LinksWrapperMobile>
        </>
      )}
    </>
  );
};

export default Navbar;
