import { useState } from "react";
import * as C from "./style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

interface DropDownProps {
  title: string;
  text: string;
}

const DropDown: React.FC<DropDownProps> = ({ title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <C.Wrapper
      $open={open}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <C.Container>
        <C.Left>
          <p>{title}</p>
        </C.Left>
        <C.Right>
          {open ? (
            <KeyboardArrowUpRoundedIcon />
          ) : (
            <KeyboardArrowDownRoundedIcon />
          )}
        </C.Right>
      </C.Container>
      <C.Container>{open && <p>{text}</p>}</C.Container>
    </C.Wrapper>
  );
};

export default DropDown;
