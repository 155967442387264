import styled from "styled-components";

export const SideBarSubMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const SidebarSubMenuItem = styled.div<{ $open: boolean }>`
  width: 100%;
  height: auto;
  background-color: #d75ba1;
  align-items: center;
  justify-content: center;
  padding: 10px;

  p {
    margin: 0;
  }

  &:hover {
    background-color: #cc5e9c;
  }
`;
