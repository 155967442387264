import { Button, Link, TextField } from "@mui/material";
import styled from "styled-components";

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f36a7b;
  padding: 50px;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 30px;
    gap: 16px;
  }
`;

export const TextArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 36px;
    font-weight: 500;
    color: white;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 25px;
    }
  }
`;

export const MediaArea = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  text-align: left;

  p {
    color: white;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const MediaButtonsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

export const MediaLink = styled(Link)`
  width: 33%;
`;

export const MediaButton = styled(Button)`
  width: 100%;
  background-color: white;
  color: #f36a7b;
  margin-top: 10px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;

  &:hover {
    background-color: lightgray;
  }

  .MuiButton-startIcon {
    img {
      @media (max-width: 768px) {
        width: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const FormArea = styled.form`
  width: 60% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const FormTextArea = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  p {
    color: white;
    font-weight: 400;
    font-size: 20px;
  }
`;

export const FormInput = styled(TextField)`
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;

  & label {
    color: #f36a7b;
  }
  & label.Mui-focused {
    color: #f36a7b;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }

  & .MuiFilledInput-root {
    &.MuiFilledInput-underline:before {
      border-bottom: 1px solid #f4ba75;
      border-radius: 4px;
    }
    &.MuiFilledInput-underline:after {
      border-bottom: 3px solid #f4ba75;
      border-radius: 4px;
    }
  }
`;

export const FormButton = styled(Button)`
  width: 30%;
  background-color: #f4ba75;
  color: white;
  height: 50px;

  &:hover {
    background-color: #e8a14d;
  }
`;
