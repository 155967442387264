import { useState } from "react";
import * as C from "./style";
import { IAcessoDTO } from "../../models/Acesso";
import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

interface AcessoProps {
  acesso: IAcessoDTO;
}

const Acesso: React.FC<AcessoProps> = ({ acesso }) => {
  const [formData, setFormData] = useState<IAcessoDTO>(acesso);

  const [flagsGD, setFlagsGD] = useState({
    Financeiro: false,
    Parceiro: false,
    ODC: false,
    Gerador: false,
    Cadastro: false,
    Comercial: false,
    Relatorio: false,
    Dev: false,
    Qualidade: false,
    Tutoriais: false,
    GatewayDePagamento: false,
  });

  const [flagsML, setFlagsML] = useState({
    Financeiro: false,
    Parceiro: false,
    ODC: false,
    Gerador: false,
    Cadastro: false,
    Comercial: false,
    Relatorio: false,
    Dev: false,
    Qualidade: false,
    Tutoriais: false,
  });

  return (
    <Stack>
      <h2>Financeiro</h2>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={flagsGD.Financeiro}
              onChange={(e) =>
                setFlagsGD((prevState) => ({
                  ...prevState,
                  Financeiro: !prevState.Financeiro,
                }))
              }
            />
          }
          label={
            <C.CheckboxLabelWrapper>
              GD
              {flagsGD.Financeiro ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </C.CheckboxLabelWrapper>
          }
        />
        {flagsGD.Financeiro && (
          <Stack direction={"column"} spacing={2}>
            {/* Gateway de pagamento */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={flagsGD.GatewayDePagamento}
                  onChange={(e) =>
                    setFlagsGD((prevState) => ({
                      ...prevState,
                      GatewayDePagamento: !prevState.GatewayDePagamento,
                    }))
                  }
                />
              }
              label={
                <C.CheckboxLabelWrapper>
                  Gateway de pagamento
                  {flagsGD.GatewayDePagamento ? (
                    <KeyboardArrowUpRoundedIcon />
                  ) : (
                    <KeyboardArrowDownRoundedIcon />
                  )}
                </C.CheckboxLabelWrapper>
              }
            />
            {flagsGD.GatewayDePagamento && (
              <Stack direction={"row"} spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acessoGatewayPagamentoGD.editar}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          acessoGatewayPagamentoGD: {
                            ...prevState.acessoGatewayPagamentoGD,
                            editar: !prevState.acessoGatewayPagamentoGD.editar,
                          },
                        }));
                      }}
                    />
                  }
                  label="Editar"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acessoGatewayPagamentoGD.excluir}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          acessoGatewayPagamentoGD: {
                            ...prevState.acessoGatewayPagamentoGD,
                            excluir:
                              !prevState.acessoGatewayPagamentoGD.excluir,
                          },
                        }));
                      }}
                    />
                  }
                  label="Excluir"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acessoGatewayPagamentoGD.consultar}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          acessoGatewayPagamentoGD: {
                            ...prevState.acessoGatewayPagamentoGD,
                            consultar:
                              !prevState.acessoGatewayPagamentoGD.consultar,
                          },
                        }));
                      }}
                    />
                  }
                  label="Consultar"
                />
              </Stack>
            )}
            {/* Fim Gateway de Pagamento */}
            {/* Gateway de pagamento */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={flagsGD.GatewayDePagamento}
                  onChange={(e) =>
                    setFlagsGD((prevState) => ({
                      ...prevState,
                      GatewayDePagamento: !prevState.GatewayDePagamento,
                    }))
                  }
                />
              }
              label={
                <C.CheckboxLabelWrapper>
                  Gateway de pagamento
                  {flagsGD.GatewayDePagamento ? (
                    <KeyboardArrowUpRoundedIcon />
                  ) : (
                    <KeyboardArrowDownRoundedIcon />
                  )}
                </C.CheckboxLabelWrapper>
              }
            />
            {flagsGD.GatewayDePagamento && (
              <Stack direction={"row"} spacing={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acessoGatewayPagamentoGD.editar}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          acessoGatewayPagamentoGD: {
                            ...prevState.acessoGatewayPagamentoGD,
                            editar: !prevState.acessoGatewayPagamentoGD.editar,
                          },
                        }));
                      }}
                    />
                  }
                  label="Editar"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acessoGatewayPagamentoGD.excluir}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          acessoGatewayPagamentoGD: {
                            ...prevState.acessoGatewayPagamentoGD,
                            excluir:
                              !prevState.acessoGatewayPagamentoGD.excluir,
                          },
                        }));
                      }}
                    />
                  }
                  label="Excluir"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acessoGatewayPagamentoGD.consultar}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          acessoGatewayPagamentoGD: {
                            ...prevState.acessoGatewayPagamentoGD,
                            consultar:
                              !prevState.acessoGatewayPagamentoGD.consultar,
                          },
                        }));
                      }}
                    />
                  }
                  label="Consultar"
                />
              </Stack>
            )}
            {/* Fim Gateway de Pagamento */}
          </Stack>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={flagsML.Financeiro}
              onChange={(e) =>
                setFlagsML((prevState) => ({
                  ...prevState,
                  Financeiro: !prevState.Financeiro,
                }))
              }
            />
          }
          label={
            <C.CheckboxLabelWrapper>
              ML
              {flagsML.Financeiro ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </C.CheckboxLabelWrapper>
          }
        />
      </FormGroup>
    </Stack>
  );
};

export default Acesso;
