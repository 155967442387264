import { useEffect, useState } from "react";
import { ISidebarSubMenu, ISidebarItem } from "../../models/SidebarPopulate";
import SidebarItem from "../SidebarItem";
import * as C from "./style";
import { useLocation } from "react-router-dom";

const SidebarSubMenu = (sidebarSubMenu: ISidebarSubMenu) => {
  const [sideBarSubMenuOpen, setSideBarSubMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    sidebarSubMenu.itens.forEach((item: ISidebarItem) => {
      if (item.path === window.location.pathname) {
        setSideBarSubMenuOpen(true);
      }
    });
  }, [location]);

  return (
    <C.SideBarSubMenuWrapper>
      <C.SidebarSubMenuItem
        $open={sideBarSubMenuOpen}
        onClick={() => setSideBarSubMenuOpen(!sideBarSubMenuOpen)}
      >
        <p>{sidebarSubMenu.title}</p>
      </C.SidebarSubMenuItem>
      {sideBarSubMenuOpen &&
        sidebarSubMenu.itens.map((item) => (
          <SidebarItem
            key={item.title}
            title={item.title}
            path={item.path}
            show={true}
          />
        ))}
    </C.SideBarSubMenuWrapper>
  );
};

export default SidebarSubMenu;
