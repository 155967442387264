import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as C from "./style";
import * as G from "../../style";
import { Checkbox, Stack, TextField } from "@mui/material";
import ImageInput from "../../../../../../../components/ImageInput";
import * as D from "../../../../../../../styles/appComponents";
import { EnumSituacaoColaborador } from "../../../../../../../enums/EnumSituacaoColaborador.enum";

const Situacao: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleImageChange = (newImage: File | null) => {
    setSelectedImage(newImage);
  };

  return (
    <RegisterCard title="Situação">
      <C.Container>
        <C.Left>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField label="Empresa" fullWidth />
            <TextField label="Número de registro" sx={{ width: "40%" }} />
          </G.FWStack>
          <G.FWStack direction={"row"} spacing={2}>
            <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
              <G.Title>Situação</G.Title>
              {Object.values(EnumSituacaoColaborador).map((value) => (
                <G.CheckboxWrapper key={value}>
                  <Checkbox
                    checked={selectedValue === value}
                    value={value}
                    onChange={(e) => handleChange(e)}
                  />
                  <p>{value}</p>
                </G.CheckboxWrapper>
              ))}
            </Stack>
            <TextField label="Código da condição" fullWidth />
            <TextField label="Condição" fullWidth />
            <D.DateTextfield type="date" label="Data afastamento" fullWidth />
          </G.FWStack>
        </C.Left>
        <C.Right>
          <ImageInput onImageChange={handleImageChange} />
        </C.Right>
      </C.Container>
    </RegisterCard>
  );
};

export default Situacao;
