import React, { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import * as C from './style'

interface ScrollTopProps {
    container: React.RefObject<HTMLInputElement>;
}

const ScrollTopButton: React.FC<ScrollTopProps> = ({ container }) => {

    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
        container.current?.scrollTo({
            top: -30,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (!showButton && container.current!.scrollTop > 200) {
                setShowButton(true);
            } else if (showButton && container.current!.scrollTop <= 200) {
                setShowButton(false);
            }
        };

        const div = container.current;
        if (div) {
            div.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (div) {
                div.removeEventListener('scroll', handleScroll);
            }
        };
    }, [showButton, container]);

    return (
        showButton ? (<C.ScrollTop onClick={scrollToTop}>
            <KeyboardArrowUpIcon />
        </C.ScrollTop>
        ) : null
    );
}

export default ScrollTopButton;