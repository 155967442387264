import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: inherit;
`;

export const Left = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: inherit;
`;

export const Right = styled.div`
  width: 25%;
  display: flex;
  padding: 0 30px;
`;
