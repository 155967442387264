import { Stack } from "@mui/material";
import styled from "styled-components";

export const TitleArea = styled.div`
  display: flex;
  gap: 40px;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const FWStack = styled(Stack)`
  width: 100%;
`;
export const FWDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
`;
