import React from "react";
import Login from "../../../../components/Login";
import cliente from "../../../../assets/Login/imgCliente.svg";
import * as C from "../../../../components/Login/style";
import { useNavigate } from "react-router-dom";

const topics: string[] = [
  "Seja bem-vindo! Sua energia é vital para o funcionamento desta empresa.",
  "Você é fundamental para o nosso crescimento e prosperidade. Obrigado por fazer parte desta equipe.",
  "Sua presença aqui é mais do que uma entrada. Você é a engrenagem que nos faz avançar.",
];

const RecuperarSenhaGerador: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Login
        title="gerador"
        topics={topics}
        imageSrc={cliente}
        formTitle="Recuperar Senha"
        formSubtitle="Esqueceu sua senha? digite seu e-mail que enviaremos um link para definir uma nova senha"
      >
        <C.LoginForm>
          <C.LoginInput label="Login" variant="outlined" />
          <C.LoginInput label="E-mail" variant="outlined" type="email" />
          <C.LoginButton
            variant="contained"
            onClick={() => navigate("/Login/Cliente")}
          >
            Recuperar
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default RecuperarSenhaGerador;
