import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SideBarMenuWrapper = styled(NavLink)<{ $open: boolean }>`
  width: 100%;
  height: 50px;
  background-color: ${(props) => (props.$open ? "#faa5d5" : "#ed7abb")};
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: #ffffff;
  transition: linear ease 0.4s;
  text-decoration: none;

  p {
    margin: 0;
  }

  &:hover {
    background-color: #faa5d5;
  }
`;
