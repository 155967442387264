import React from 'react';
import * as C from './style';

type CardBenefitsProps = {
    title: string;
    description: string;
    imageSrc: string;
}

const CardBenefits: React.FC<CardBenefitsProps> = ({ title, description, imageSrc }) => {

    return (
        <C.CardWrapper>
            <C.Img src={imageSrc} alt="cardImage" />
            <C.Title>{title}</C.Title>
            <C.Description>{description}</C.Description>
        </C.CardWrapper>
    )
};

export default CardBenefits;