import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: () => void, ignoreRefs: React.RefObject<HTMLElement>[]) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            const isClickInside = ignoreRefs.some(ignoreRef => ignoreRef.current && ignoreRef.current.contains(event.target as Node));
            if (isClickInside) {
                return;
            }

            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mouseup', handleClickOutside);
        document.addEventListener('touchend', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
            document.removeEventListener('touchend', handleClickOutside);
        };
    }, [callback, ignoreRefs]);

    return ref;
};
