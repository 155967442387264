import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as G from "../../style";
import { Chip, MenuItem, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { EnumSexo } from "../../../../../../../enums/EnumSexo.enum";
import { EnumEstadoCivil } from "../../../../../../../enums/EnumEstadoCivil.enum";
import { EnumGrauInstrucao } from "../../../../../../../enums/EnumGrauInstrucao.enum";
import { EnumTipoChavePix } from "../../../../../../../enums/EnumTipoChavePix.enum";

const DadosPessoais: React.FC = () => {
  const [selectedChipId, setSelectedChipId] = useState<string>("");

  const handleChipClick = (chipId: string) => () => {
    setSelectedChipId(chipId);
  };
  return (
    <RegisterCard title="Dados pessoais">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField label="Nome" fullWidth />
        <D.DateTextfield type="date" label="Data de nascimento" fullWidth />
        <TextField select label="Sexo" fullWidth>
          {Object.values(EnumSexo).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField select label="Estado civil" fullWidth>
          {Object.values(EnumEstadoCivil).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField select label="Grau de instrução" fullWidth>
          {Object.values(EnumGrauInstrucao).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <TextField label="Município de nascimento" fullWidth />
        <TextField label="Nacionalidade" fullWidth />
        <D.DateTextfield type="date" label="Data de admissão" fullWidth />
        <TextField label="CEP" fullWidth />
        <TextField label="UF" sx={{ width: "30%" }} />
        <TextField label="Cidade" fullWidth />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <TextField label="Bairro" fullWidth />
        <TextField label="Logradouro" fullWidth />
        <TextField label="Número" sx={{ width: "30%" }} />
        <TextField label="Complemento" fullWidth />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={16}>
        <G.MWDiv>
          <G.Subtitle>Dados bancários*</G.Subtitle>
          <G.Text>Insira seus dados bancários</G.Text>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField label="Banco" required fullWidth />
            <TextField label="Agência" required fullWidth />
          </G.FWStack>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField label="Conta" required fullWidth />
            <TextField label="CPF do favorecido" required fullWidth />
          </G.FWStack>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField label="Favorecido" required fullWidth />
            <G.FWDiv></G.FWDiv>
          </G.FWStack>
        </G.MWDiv>
        <G.MWDiv>
          <G.Subtitle>Pix*</G.Subtitle>
          <G.Text>Qual dado você quer usar?</G.Text>
          <G.FWStack direction="row" spacing={2}>
            {Object.values(EnumTipoChavePix).map((label) => (
              <Chip
                key={label}
                label={label}
                onClick={handleChipClick(label)}
                color={selectedChipId === label ? "primary" : "default"}
              />
            ))}
          </G.FWStack>
          <G.Text>Insira sua chave pix</G.Text>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField label="Chave PIX" required fullWidth />
          </G.FWStack>
        </G.MWDiv>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton>Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default DadosPessoais;
