import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

export const Circle = styled.div<{ color: number; selected: boolean }>`
  width: 16px;
  height: 16px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${({ color }) => {
    switch (color) {
      case 0:
        return "blue";
      case 1:
        return "yellow";
      case 2:
        return "green";
      case 3:
        return "red";
      case 4:
        return "purple";
      default:
        return "transparent";
    }
  }};

  background-color: ${({ color, selected }) => {
    if (!selected) {
      return "white";
    }
    switch (color) {
      case 0:
        return "blue";
      case 1:
        return "yellow";
      case 2:
        return "green";
      case 3:
        return "red";
      case 4:
        return "purple";
      default:
        return "white";
    }
  }};
`;
