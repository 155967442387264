export enum EnumTipoDocumento {
  CPF = "CPF",
  CNPJ = "CNPJ",
}

export const TipoDocumentoNumberMap: {
  [key: number]: EnumTipoDocumento;
} = {
  0: EnumTipoDocumento.CPF,
  1: EnumTipoDocumento.CNPJ,
};
