import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import * as C from "./style";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface LoginProps {
  title: string;
  topics: string[];
  imageSrc: string;
  formTitle: string;
  formSubtitle: string;
}

type LoginPropsWithChildren = React.FC<React.PropsWithChildren<LoginProps>>;

const Login: LoginPropsWithChildren = ({
  children,
  title,
  topics,
  imageSrc,
  formTitle,
  formSubtitle,
}) => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <C.Container>
      <C.BackButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </C.BackButton>
      <C.LeftSide>
        <C.LeftContent>
          <C.TitleArea>
            <h1>Bem-vindo à sua área {title}!</h1>
          </C.TitleArea>
          <C.DescriptionArea>
            <C.TopicsArea>
              <ul>
                {topics.map((topic, index) => (
                  <li key={index}>{topic}</li>
                ))}
              </ul>
            </C.TopicsArea>
            <C.ImageArea>
              <img src={imageSrc} alt="imgColaborador" />
            </C.ImageArea>
          </C.DescriptionArea>
        </C.LeftContent>
      </C.LeftSide>
      <C.RightSide>
        <img onClick={() => navigate("/")} src={logo} alt="logoWattwise" />
        <C.TextArea>
          <C.Title>
            {isMobile ? `Bem-vindo à sua área ${title}!` : formTitle}
          </C.Title>
          <p>{formSubtitle}</p>
        </C.TextArea>
        <C.FormArea>{children}</C.FormArea>
      </C.RightSide>
    </C.Container>
  );
};

export default Login;
