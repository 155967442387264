import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import FolderIcon from "../../../../assets/Plataforma/folderIcon.svg";
import CircleCheckbox from "../../../../components/CircleCheckBox";
import StatusCard from "../../../../components/StatusCard";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";

const Parceiro: React.FC = () => {
  const [page, setPage] = useState(1);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [openModalDocuments, setOpenModalDocuments] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModalSeeMore = () => {
    setOpenModalSeeMore(true);
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleOpenModalDocuments = () => {
    setOpenModalDocuments(true);
  };

  const handleCloseModalDocuments = () => {
    setOpenModalDocuments(false);
  };

  const handleSelect = (id: number) => {
    setSelectedId(id);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "responsavel",
      headerName: "Responsável legal",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "celular", headerName: "Celular", flex: 1 },
    {
      field: "tipoParceiro",
      headerName: "Tipo Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "prazo",
      headerName: "Prazo Contratual",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "backoffice",
      headerName: "Backoffice",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "documentos",
      headerName: "Documentos",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.GridImage
            src={FolderIcon}
            alt="folderIcon"
            onClick={() => handleOpenModalDocuments()}
          />
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpenModalSeeMore()}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = [
    {
      id: 0,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "M",
      prazo: "Indeterminado",
      backoffice: "Rebeca de Assis",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 1,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "AM",
      prazo: "08/05/2025",
      backoffice: "Rodrigo Tomaz",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 2,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "M",
      prazo: "Indeterminado",
      backoffice: "Rebeca de Assis",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 3,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "AM",
      prazo: "08/05/2025",
      backoffice: "Rodrigo Tomaz",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 4,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "M",
      prazo: "Indeterminado",
      backoffice: "Rebeca de Assis",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 5,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "AM",
      prazo: "08/05/2025",
      backoffice: "Rodrigo Tomaz",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 6,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "M",
      prazo: "Indeterminado",
      backoffice: "Rebeca de Assis",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 7,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "AM",
      prazo: "08/05/2025",
      backoffice: "Rodrigo Tomaz",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 8,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "M",
      prazo: "Indeterminado",
      backoffice: "Rebeca de Assis",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
    {
      id: 9,
      responsavel: "José Luiz Almeida",
      celular: "(37) 984563123",
      tipoParceiro: "AM",
      prazo: "08/05/2025",
      backoffice: "Rodrigo Tomaz",
      documentos: 0,
      status: 0,
      outras: "ver mais",
    },
  ];

  const columnsModal: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "responsavel", headerName: "Responsavel", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data", flex: 1 },
  ];

  const rowsModal = [
    {
      id: 1,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 2,
      responsavel: "Rebecca de Assis",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 3,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 4,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 1,
      data: "05/11/2013",
    },
    {
      id: 5,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 1,
      data: "05/11/2013",
    },
  ];

  const columnsModalDocument: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    { field: "data", headerName: "Data Upload", flex: 1 },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDownloadButton
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            Baixar
          </C.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDeleteButton
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Deletar
          </C.ModalGridDeleteButton>
        </D.GridField>
      ),
    },
  ];

  const rowsModalDocument = [
    {
      id: 1,
      descricao: "Conta Cemig",
      data: "05/11/2013",
      anexo: 0,
      remover: 0,
    },
    {
      id: 2,
      descricao: "Documento Pessoal",
      data: "05/11/2013",
      anexo: 0,
      remover: 0,
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard color="yellow" text="Lead" number={254} />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="blue"
                text="Aguardando assinatura do contrato"
                number={56}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="green" text="Contrato assinado" number={103} />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="red"
                text="Aguardando assinatua de distrato"
                number={39}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="purple" text="Cancelado" number={39} />
            </C.StatusWrapper>
          </C.StatusArea>
        )}

        <D.DataArea $align="right">
          <C.HeaderTable>
            <D.ContainedButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate("/Cadastro/Parceiro")}
            >
              Novo parceiro
            </D.ContainedButton>
            <D.DefaultSearchInput
              size="small"
              variant="outlined"
              onChange={() => null}
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </C.HeaderTable>

          {!isMobile ? (
            <D.DefaultTable
              sx={{
                border: "none",
                width: "100%",
              }}
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              columns={columns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          ) : (
            <C.CardDataAreaMobile>
              {rows.map((row) => (
                <C.CardDataMobile key={row.id}>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Id:</label>
                      <p>{row.id}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile></C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Status:</label>
                      <D.Circle color={"green"} />
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Responsável legal:</label>
                      <p>{row.responsavel}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Parceiro:</label>
                      <p>{row.celular}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Backoffice:</label>
                      <p>{row.backoffice}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Prazo contratual:</label>
                      <p>{row.prazo}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Tipo parceiros:</label>
                      <p>{row.tipoParceiro}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Documentos:</label>
                      <D.GridImage
                        onClick={() => handleOpenModalDocuments()}
                        src={FolderIcon}
                        alt="folderIcon"
                      />
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label></label>
                      <D.DefaultGridButton
                        onClick={() => handleOpenModalSeeMore()}
                      >
                        Ver mais
                      </D.DefaultGridButton>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                </C.CardDataMobile>
              ))}
            </C.CardDataAreaMobile>
          )}

          <D.DefaultPagination
            count={10}
            size={isMobile ? "small" : "medium"}
            siblingCount={isMobile ? 0 : 1}
            showFirstButton
            showLastButton
            onChange={handleChange}
            color="primary"
          />
          <C.LineBreak />
          <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status de propostas</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%" }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Parceiro - 9591</h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalStatusArea>
                <CircleCheckbox
                  id={0}
                  label="Em análise"
                  selected={true}
                  onSelect={handleSelect}
                />
              </C.ModalStatusArea>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Nome do parceiro</label>
                  <p>Julio andrade silva</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Celular do perceiro</label>
                  <p>(31) 974280912</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Nome do master</label>
                  <p>Julio andrade silva</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Celular do master</label>
                  <p>(31) 974280912</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Backoffice</label>
                  <p>Rebeca de assis</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Convênio</label>
                  <p>Wegen coop</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Vencimento do contrato</label>
                  <p>Indefinido</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Formas de pagamento</label>
                  <p>Transferência</p>
                  <p>Banco: 0000 Nu pagamentos S.A</p>
                  <p>AG: 0000</p>
                  <p>Conta: 00000000-0</p>
                </C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
      <Modal
        sx={{ width: "100%" }}
        open={openModalDocuments}
        onClose={handleCloseModalDocuments}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Parceiro - 9591</h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalDocuments}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Parceiro:</label>
                  <p>João Carlos Cunha Melo</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Celular:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Status atual:</label>
                  <C.ModalTextIcon>
                    <D.Circle color={"green"} />
                    <p>Contrato assinado</p>
                  </C.ModalTextIcon>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Parceiro master:</label>
                  <p>GCM energia solar</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Celular:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  <PublishIcon /> Upload de Arquivos
                </h2>
              </C.ModalHeader>
              <TextField
                label="Descrição do documento da proposta:"
                fullWidth
                multiline
                rows={5}
              />
              <C.ModalButtonsArea>
                <C.ModalDocumentButton
                  variant="contained"
                  startIcon={<PublishIcon />}
                >
                  Anexar Documento
                </C.ModalDocumentButton>
                <C.ModalSaveButton variant="contained">
                  Anexar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Download anexo proposta</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModalDocument}
                columns={columnsModalDocument}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: rowsModalDocument.length,
                    },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default Parceiro;
