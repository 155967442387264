import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  background-color: #f36a7b;
`;

export const Top = styled.div`
  display: flex;
  width: 100%;
  height: 85%;

  padding: 30px 50px;
`;

export const Left = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #ffffff;
  justify-content: center;
  align-items: flex-start;

  h3 {
    max-width: 70%;
    margin: 0;
    font-size: 30px;
    font-weight: 500;
  }

  p {
    max-width: 70%;
    margin: 0;
    font-size: 18px;
    font-weight: 300;
  }
`;

export const BannerButton = styled.button`
  width: auto;
  background-color: #ffffff;
  color: #f36a7b;
  height: 40px;
  border: none;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;

  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  &:hover {
    background-color: #f2f2f2;
    transform: scale(102%);
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  height: 100%;

  img {
    width: 400px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  height: 15%;
  border-top: 1px solid white;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  a {
    color: #ffffff;
    cursor: pointer;

    &:hover {
      transform: scale(102%);
    }
  }
`;
