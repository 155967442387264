import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div<{ $open: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid #f36a7b;
  padding: 0 16px;
  align-items: center;
  flex: 1;
  border-radius: 10px;
  height: ${(props) => (props.$open ? "auto" : "60px")};
  transition: height ease 0.2s;
  color: #3e3e3e;

  &:hover {
    transform: scale(102%);
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  width: 90%;
  display: flex;

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const Right = styled.div`
  width: 10%;
  color: #f36a7b;

  svg {
    font-size: 50px;
  }
`;
