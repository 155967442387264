import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as G from "../../style";
import { Checkbox, Chip, MenuItem, Stack, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { EnumContribuicaoSindical } from "../../../../../../../enums/EnumContribuicaoSindical.enum";
import { EnumTipoVaga } from "../../../../../../../enums/EnumTipoVaga.enum";
import { EnumHorasSemanais } from "../../../../../../../enums/EnumHorasSemanais.enum";

const SalarioFuncao: React.FC = () => {
  const [periodoDeExperiencia, setPeriodoDeExperiencia] = useState(false);
  const [selectedValueContribuicao, setSelectedValueContribuicao] =
    useState("");
  const [selectedValueVaga, setSelectedValueVaga] = useState("");
  const [selectedValueHoras, setSelectedValueHoras] = useState("");

  const handleChangeSelectContribuicao = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueContribuicao(event.target.value);
  };

  const handleChangeSelectVaga = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueVaga(event.target.value);
  };

  const handleChangeSelectHoras = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueHoras(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPeriodoDeExperiencia(event.target.value === "true");
  };

  return (
    <RegisterCard title="Salário/função">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField label="Salário base" fullWidth />
        <TextField label="Função" fullWidth />
        <TextField label="Partido sindicato" fullWidth />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          select
          label="Periodo de experiência"
          value={String(periodoDeExperiencia)}
          onChange={handleChange}
          sx={{ width: "20%" }}
        >
          <MenuItem key="true" value="true">
            Sim
          </MenuItem>
          <MenuItem key="false" value="false">
            Não
          </MenuItem>
        </TextField>

        {periodoDeExperiencia && (
          <>
            <D.DateTextfield type="date" label="Inicio" sx={{ width: "20%" }} />
            <D.DateTextfield type="date" label="Fim" sx={{ width: "20%" }} />
          </>
        )}
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={8}>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Incidência</G.Title>
          <G.CheckboxWrapper>
            <Checkbox />
            <p>Incide INSS</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox />
            <p>Incide IRF</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox />
            <p>Incide FGTS</p>
          </G.CheckboxWrapper>
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Contribuição sindical</G.Title>
          {Object.values(EnumContribuicaoSindical).map((value) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                checked={selectedValueContribuicao === value}
                value={value}
                onChange={(e) => handleChangeSelectContribuicao(e)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Tipo de vaga</G.Title>
          {Object.values(EnumTipoVaga).map((value) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                checked={selectedValueVaga === value}
                value={value}
                onChange={(e) => handleChangeSelectVaga(e)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Tipo de vaga</G.Title>
          {Object.values(EnumHorasSemanais).map((value) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                checked={selectedValueHoras === value}
                value={value}
                onChange={(e) => handleChangeSelectHoras(e)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton>Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default SalarioFuncao;
