import styled from "styled-components";

export const SideBarMenuWrapper = styled.div<{ $open: boolean }>`
  width: 100%;
  height: ${(props) => (props.$open ? "auto" : "50px")};
  border-radius: 5px;
  background-color: ${(props) => (props.$open ? "#D24293" : "#FFFFFF")};
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => (props.$open ? "#FFFFFF" : "#D24293")};
  transition: linear ease 0.4s;

  p {
    margin: 0;
  }

  &:hover {
    background-color: ${(props) => (props.$open ? "#bf3d87" : "#f1f1f1")};
  }
`;

export const SideBarItem = styled.div<{ $open: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;

  svg {
    fill: ${(props) => (props.$open ? "#f1f1f1" : "#bf3d87")};
    color: ${(props) => (props.$open ? "#f1f1f1" : "#bf3d87")};
  }
`;

export const SideBarFooter = styled.div`
  width: 100%;
  height: 10px;
`;

export const ClosedSidebarMenu = styled.div`
  width: 250px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 1;
  left: 70px;
  background-color: inherit;
  border-radius: 5px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  z-index: 99;
  transition: all ease 4ms;
`;
