import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as G from "../../style";
import { Stack, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { IAcessoDTO } from "../../../../../../../models/Acesso";
import Acesso from "../../../../../../../components/Acesso";

const DadosAcesso: React.FC = () => {
  const [formData, setFormData] = useState<IAcessoDTO>({
    id: null,
    acessoGatewayPagamentoGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoBuscarPixGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoUsinaGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoBancarioGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoInadimplentesGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoRecebimentoDiarioGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoCadastroCupomGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturasCanceladasGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturasRefaturadasGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturasParceladasGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoContratoParceiroGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoComissaoParceiroGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoParceiroGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoListaBackOfficeGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoComissaoBackOfficeGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoBackOfficeGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoAbrirODCGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoEmitirODCGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoListarODCGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturarODCGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoAuditarODCGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoBuscarXMLDistribuidoraGD: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoGatewayPagamentoML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoBuscarPixML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoUsinaML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoBancarioML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoInadimplentesML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoRecebimentoDiarioML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoCadastroCupomML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturasCanceladasML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturasRefaturadasML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturasParceladasML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoContratoParceiroML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoComissaoParceiroML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoParceiroML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoListaBackOfficeML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoComissaoBackOfficeML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoExtratoBackOfficeML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoAbrirODCML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoEmitirODCML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoListarODCML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoFaturarODCML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoAuditarODCML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
    acessoBuscarXMLDistribuidoraML: {
      id: null,
      idAcesso: null,
      consultar: false,
      editar: false,
      excluir: false,
    },
  });

  return (
    <RegisterCard title="Dados de acesso">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField label="Login" fullWidth />
        <TextField label="Senha" fullWidth />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <Acesso acesso={formData} />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton>Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default DadosAcesso;
