export enum EnumStatusLead {
  leads = "Leads",
  aguardandoEnvioConta = "Aguardando envio de conta",
  contaAnexada = "Conta anexada",
  cancelada = "Cancelada",
}

export const EnumStatusLeadNumericMap = {
  [EnumStatusLead.leads]: 0,
  [EnumStatusLead.aguardandoEnvioConta]: 1,
  [EnumStatusLead.contaAnexada]: 2,
  [EnumStatusLead.cancelada]: 3,
};

export const EnumStatusLeadColorMap: Record<
  number,
  "yellow" | "blue" | "green" | "red" | "purple"
> = {
  0: "blue",
  1: "yellow",
  2: "green",
  3: "red",
};
