import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ISidebarItem,
  ISidebarMenu,
  ISidebarSubMenu,
} from "../../models/SidebarPopulate";
import SidebarSubMenu from "../SidebarSubMenu";
import * as C from "./style";

/*
  SidebarMenuProps

  open: {
    Open se refere a quando o menu é clicado, ficando rosa, quando não há submenus ele deve 
    redirecionar para o path do submenu.
  }

  xOpen: {
    xOpen é abertura lateral, 
  }
*/

interface SidebarMenuProps {
  sidebarMenuItem: ISidebarMenu;
  onDataReceived: (data: string) => void;
  open: boolean;
  xOpen: boolean;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  sidebarMenuItem,
  onDataReceived,
  open,
  xOpen,
}) => {
  const [sideBarMenuOpen, setSideBarMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenSideBarMenu = () => {
    if (sidebarMenuItem.path && xOpen) {
      handleNavigate();
    }
    onDataReceived(sidebarMenuItem.title);
  };

  const handleNavigate = () => {
    if (sidebarMenuItem.path) {
      navigate(sidebarMenuItem.path);
    }
  };

  const verificarPath = (menu: ISidebarMenu | ISidebarSubMenu) => {
    if ("itens" in menu && Array.isArray(menu.itens)) {
      menu.itens.forEach((item: ISidebarItem) => {
        if (item.path === window.location.pathname) {
          setSideBarMenuOpen(true);
        }
      });
    }

    if ("subMenus" in menu && Array.isArray(menu.subMenus)) {
      menu.subMenus.forEach(verificarPath);
    }
  };

  useEffect(() => {
    if (sidebarMenuItem.path && sidebarMenuItem.path === location.pathname) {
      setSideBarMenuOpen(true);
    } else {
      verificarPath(sidebarMenuItem);
    }

    if (sidebarMenuItem.path && sidebarMenuItem.path !== location.pathname) {
      setSideBarMenuOpen(false);
    }
  }, [location]);

  useEffect(() => {
    setSideBarMenuOpen(open);
  }, [open]);

  return (
    <C.SideBarMenuWrapper $open={sideBarMenuOpen}>
      <C.SideBarItem $open={sideBarMenuOpen} onClick={handleOpenSideBarMenu}>
        {sidebarMenuItem.icon({})}
        {xOpen && <p>{sidebarMenuItem.title}</p>}
      </C.SideBarItem>
      {sidebarMenuItem.subMenus &&
        sideBarMenuOpen &&
        xOpen &&
        sidebarMenuItem.subMenus.map((item) => (
          <SidebarSubMenu
            key={item.title}
            title={item.title}
            itens={item.itens}
          />
        ))}
      {sideBarMenuOpen && !xOpen && (
        <C.ClosedSidebarMenu>
          <C.SideBarItem $open={sideBarMenuOpen} onClick={handleNavigate}>
            <p>{sidebarMenuItem.title}</p>
          </C.SideBarItem>
          {sidebarMenuItem.subMenus &&
            sidebarMenuItem.subMenus.map((item) => (
              <SidebarSubMenu
                key={item.title}
                title={item.title}
                itens={item.itens}
              />
            ))}
          {sidebarMenuItem.subMenus && sideBarMenuOpen && <C.SideBarFooter />}
        </C.ClosedSidebarMenu>
      )}
      {sidebarMenuItem.subMenus && sideBarMenuOpen && xOpen && (
        <C.SideBarFooter />
      )}
    </C.SideBarMenuWrapper>
  );
};

export default SidebarMenu;
