import { Chip } from "@mui/material";
import * as C from "./style";

interface StatusCardProps {
  color: "blue" | "yellow" | "green" | "red" | "purple";
  text: string;
  number: number;
}

const StatusCard: React.FC<StatusCardProps> = ({ color, text, number }) => {
  return (
    <C.Card>
      <C.Left>
        <C.Circle color={color}></C.Circle>
        <C.Text>{text}</C.Text>
      </C.Left>
      <Chip label={number} />
    </C.Card>
  );
};

export default StatusCard;
