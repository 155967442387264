import { Button, IconButton, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const BackButton = styled(IconButton)`
  position: absolute;
  margin: 30px 0 0 30px;
  background-color: white;

  svg {
    color: #f36a7b;
  }

  &:hover {
    background-color: #e6e3e3;
  }

  @media (max-width: 800px) {
    margin: 10px 0 0 10px;
  }
`;

export const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  max-height: 100%;
  background-color: #f36a7b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  margin-bottom: 40px;
  text-align: center;

  h1 {
    margin: 0;
    color: #ffffff;
  }
`;

export const DescriptionArea = styled.div`
  width: 100%;
  display: flex;
`;

export const TopicsArea = styled.div`
  width: 60%;
  color: #ffffff;

  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }

  li {
    margin: 10px 0;
  }
`;

export const ImageArea = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  img {
    width: 80%;
  }
`;

export const RightSide = styled.div`
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;

  img {
    cursor: pointer;
    width: 200px;
    margin-bottom: 30px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
  }
`;

export const TextArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  p {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    width: 70%;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 18px;
      width: 100%;
    }
  }
`;

export const Title = styled.h3`
  margin: 0 !important;
  font-weight: 400;
  font-size: 26px;

  @media (max-width: 800px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const FormArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const LoginForm = styled.form`
  display: flex;
  gap: 20px;
  width: 70%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const LoginInput = styled(TextField)`
  width: 100%;

  & label.Mui-focused {
    color: #6b767f;
  }
  & .MuiInput-underline:after {
    border-bottom-color: green;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
    }
    &:hover fieldset {
      border-color: #6b767f;
    }
    &.Mui-focused fieldset {
      border-color: #6b767f;
    }
  }
`;

export const LoginButton = styled(Button)`
  width: 100%;
  background-color: #f36a7b;
  color: white;
  margin-top: 10px;
  height: 50px;

  &:hover {
    background-color: #f36a7b;
  }
`;

export const LinksArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Links = styled(Link)`
  text-decoration: none;
  color: #0d1fd6;

  &:hover {
    cursor: pointer;
  }
`;
