import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 23%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 45%;
  }

  @media (max-width: 320px) {
    width: 100%;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 200px;
  }

  @media (max-width: 425px) {
    height: 150px;
  }
`;

export const Title = styled.p`
  font-weight: 500px;
  font-size: 24px;
  color: #f36a7b;
  margin: 16px 0;
  min-height: 55px;

  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 500;
    min-height: 45px;
  }

  @media (max-width: 550px) {
    font-size: 20px;
    font-weight: 500;
    min-height: 70px;
  }

  @media (max-width: 320px) {
    font-size: 20px;
    font-weight: 500;
    min-height: 0;
  }
`;

export const Description = styled.p`
  font-weight: 400px;
  font-size: 16px;
  color: #333333;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 400;
  }
`;
