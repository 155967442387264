export enum EnumTipoChavePix {
  CPF = "CPF",
  CNPJ = "CNPJ",
  email = "Email",
  celular = "Celular",
  aleatoria = "Aleatória",
}

export const TipoChavePixNumberMap: {
  [key: number]: EnumTipoChavePix;
} = {
  0: EnumTipoChavePix.CPF,
  1: EnumTipoChavePix.CNPJ,
  2: EnumTipoChavePix.email,
  3: EnumTipoChavePix.celular,
  4: EnumTipoChavePix.aleatoria,
};
