import * as C from "./style";
import gif from "../../../../../../assets/LandingPageParceiro/becomePartner.gif";
import { Stack } from "@mui/material";

const BecomePartner = () => {
  return (
    <C.Container>
      <C.Title>
        Como se tornar um parceiro <C.MarkedText>Watt Wise</C.MarkedText>
      </C.Title>
      <C.TextWrapper>
        <C.Left>
          <Stack direction={"column"} spacing={4}>
            <C.TopicsWrapper>
              <C.CircleTopic>1</C.CircleTopic>
              <p>Acesse quero ser parceiro e faça seu cadastro.</p>
            </C.TopicsWrapper>
            <C.TopicsWrapper>
              <C.CircleTopic>2</C.CircleTopic>
              <p>Preencha todos os campos e anexe a sua documentação.</p>
            </C.TopicsWrapper>
            <C.TopicsWrapper>
              <C.CircleTopic>3</C.CircleTopic>
              <p>
                Nossa equipe entrará em contato para realizar o seu treinamento.
              </p>
            </C.TopicsWrapper>
            <C.TopicsWrapper>
              <C.CircleTopic>4</C.CircleTopic>
              <p>Pronto, você já é um parceiro Watt Wise!!!</p>
            </C.TopicsWrapper>
            <C.TopicsWrapper>
              <C.BecomeButton>Quero ser parceiro</C.BecomeButton>
            </C.TopicsWrapper>
          </Stack>
        </C.Left>
        <C.Right>
          <img src={gif} alt="SeTorneParceiroGif" />
        </C.Right>
      </C.TextWrapper>
    </C.Container>
  );
};

export default BecomePartner;
