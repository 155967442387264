import * as C from "./style";
import { Stack } from "@mui/material";
import DropDown from "../../../../../../components/DropDown";

const Questions = () => {
  return (
    <C.Container>
      <C.TitleArea>
        <h2>Dúvidas Frequentes</h2>
        <p>
          Veja aqui as respostas para as dúvidas mais frequentes sobre nossos
          serviços
        </p>
      </C.TitleArea>
      <Stack direction={"row"} spacing={4}>
        <DropDown
          title="Tenho que pagar para me tornar parceiro?"
          text="teste"
        />
        <DropDown
          title="Qual a forma de pagamento das indicações ?"
          text="teste"
        />
      </Stack>
      <Stack direction={"row"} spacing={4}>
        <DropDown
          title="O que o parceiro ganha em cada contrato que indica ?"
          text="teste"
        />
        <DropDown
          title="Em quanto tempo eu recebo por uma indicação ?"
          text="teste"
        />
      </Stack>
      <Stack direction={"row"} spacing={4}>
        <DropDown title="Pagamentos e Vencimentos" text="teste" />
        <DropDown title="Mudanças e Cancelamento" text="teste" />
      </Stack>
    </C.Container>
  );
};

export default Questions;
