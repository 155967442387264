import BeneficiosComissao from "./components/BeneficiosComissao";
import CRM from "./components/CRM";
import DadosAcesso from "./components/DadosAcesso";
import DadosPessoais from "./components/DadosPessoais";
import Documentos from "./components/Documentos";
import Ferias from "./components/Ferias";
import InformacoesMedicas from "./components/InformacoesMedicas";
import SalarioFuncao from "./components/SalarioFuncao";
import Situacao from "./components/Situacao";

const CadastroColaborador = () => {
  return (
    <>
      <Situacao />
      <DadosPessoais />
      <SalarioFuncao />
      <BeneficiosComissao />
      <Documentos />
      <InformacoesMedicas />
      <Ferias />
      <CRM />
      <DadosAcesso />
    </>
  );
};

export default CadastroColaborador;
