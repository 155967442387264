import React from "react";
import Login from "../../../../components/Login";
import parceiro from "../../../../assets/Login/imgParceiro.svg";
import * as C from "../../../../components/Login/style";
import { useNavigate } from "react-router-dom";

const topics: string[] = [
  "Processos otimizados para aumentar sua produtividade.",
  "Design moderno e amigável para uma experiência mais fluida.",
  "Navegue com facilidade em nossa  interface intuitiva.",
];

const RecuperarSenhaParceiro: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Login
        title="parceiro"
        topics={topics}
        imageSrc={parceiro}
        formTitle="Recuperar Senha"
        formSubtitle="Esqueceu sua senha? digite seu e-mail que enviaremos um link para definir uma nova senha"
      >
        <C.LoginForm>
          <C.LoginInput label="Login" variant="outlined" />
          <C.LoginInput label="E-mail" variant="outlined" type="email" />
          <C.LoginButton
            variant="contained"
            onClick={() => navigate("/Login/Parceiro")}
          >
            Recuperar
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default RecuperarSenhaParceiro;
