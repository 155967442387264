import styled from "styled-components";

export const NavbarWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 50px;
  z-index: 999994;

  @media (max-width: 1024px) {
    padding: 10px;
    height: 80px !important;
  }
`;

export const Logo = styled.img`
  width: 150px;
  height: auto;
  object-fit: contain;

  @media (max-width: 1024px) {
    width: 100px;
  }
`;

export const LinksWrapperDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1250px) {
    display: none;
  }
`;

export const Links = styled.a`
  text-decoration: none;
  color: #333333;
  white-space: nowrap;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  &:hover {
    cursor: pointer;
  }
`;

export const LinksButton = styled.button<{ $outlined?: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.$outlined ? "#FFFFFF" : "#F36A7B")};
  color: ${(props) => (props.$outlined ? "#F36A7B" : "#FFFFFF")};
  border: 1px solid #f36a7b;
  height: 40px;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;

  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  &:hover {
    background-color: ${(props) => (props.$outlined ? "#f2f2f2" : "#d95767")};
  }
`;

export const HamburguerButton = styled.button`
  display: none;

  @media (max-width: 1250px) {
    display: block;
    color: #f36a7b;
    background-color: white;
    border: none;
  }
`;

export const WrapperBlock = styled.div`
  display: none;

  @media (max-width: 1250px) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    overflow: hidden;
  }
`;

export const LinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  border-radius: 10px;
  padding: 8px 20px;

  border: 1px solid #ededed;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const LinksWrapperMobile = styled.div`
  display: none;

  @media (max-width: 1250px) {
    width: 100%;
    background-color: white;
    display: flex;
    position: absolute;
    z-index: 3;
    flex-direction: column;
    padding: 10px 20px 20px 20px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 0.3s ease;
  }
`;
