import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  border: 1px solid #bdbdbd;
  cursor: pointer;

  &:hover {
    border-color: black;
  }

  &:focus {
    border: 2px solid blue;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
`;

export const VisuallyHiddenInput = styled.input`
  display: none;
`;
