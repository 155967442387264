import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import * as D from "../../styles/appComponents";
import * as C from "./style";

interface DatatableProps {
  columns: GridColDef[];
  rows: any;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  onChangePage: (value: number) => void;
  onChangePageSize: (value: number) => void;
}

const Datatable: React.FC<DatatableProps> = ({
  columns,
  rows,
  pageNumber,
  pageSize,
  totalPages,
  onChangePage,
  onChangePageSize,
}) => {
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onChangePage(value);
  };
  const handleChangePageSize = (value: number) => {
    onChangePageSize(value);
  };
  return (
    <C.Wrapper>
      {rows.length > 0 ? (
        <>
          <C.Header>
            <Select
              size="small"
              value={pageSize}
              onChange={(e) => handleChangePageSize(Number(e.target.value))}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <D.DefaultSearchInput
              size="small"
              variant="outlined"
              onChange={() => null}
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </C.Header>
          <D.DefaultTable
            disableColumnResize
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            hideFooter
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
          />
          <D.DefaultPagination
            page={pageNumber}
            count={totalPages}
            size={"medium"}
            siblingCount={1}
            showFirstButton
            showLastButton
            onChange={handleChangePage}
            color="primary"
          />
        </>
      ) : (
        <C.NoDataDiv>Não há dados.</C.NoDataDiv>
      )}
    </C.Wrapper>
  );
};

export default Datatable;
