import { Stack } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    height: auto;
  }
`;

export const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.theme.corPrimaria};
  padding: 10px 60px;

  h1 {
    margin: 0;
  }

  img {
    width: auto;
    height: 100%;
  }

  @media (max-width: 1024px) {
    padding: 10px 20px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  background-color: ${(props) => props.theme.corSecundaria};
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
  }
`;

export const Left = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 50px;

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    min-height: 300px;
  }
`;

export const Right = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
`;

export const FormArea = styled.form`
  width: 90%;
  max-height: 90%;
  border-radius: 10px;
  padding: 30px 50px;
  background-color: ${(props) => props.theme.corPrimaria};
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    max-height: none;
    border-radius: 0;
    padding: 30px;
  }
`;

export const FormTitle = styled.h1`
  color: ${(props) => props.theme.corTextoBase};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

export const FormSubtitle = styled.p`
  color: ${(props) => props.theme.corTextoBase};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  margin-bottom: 10px;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const FWStack = styled(Stack)`
  width: 100%;
`;

export const InputArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    color: ${(props) => props.theme.corTextoBase};
  }
`;

export const VisuallyHiddenInput = styled.input`
  display: none;
`;

export const BillsArea = styled(Stack)`
  background-color: white;
  border-radius: 10px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    color: #1c1c1c;
    word-break: break-all;

    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
`;

export const Text = styled.p`
  color: ${(props) => props.theme.corTextoBase};
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

export const MarkedText = styled.span`
  color: ${(props) => props.theme.corTextoDestaque};
`;

export const Wpp = styled.a`
  display: flex;
  align-items: center;
  font-size: 24px !important;
  text-decoration: none;
  cursor: pointer;
  gap: 10px;

  &:hover {
    text-decoration: none;
    transform: scale(102%);
  }

  svg {
    color: ${(props) => props.theme.corTextoBase};
  }
`;

export const WppNumber = styled.h4`
  color: ${(props) => props.theme.corTextoBase};
  margin: 0 !important;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const DDD = styled.span`
  font-size: 20px;
`;
