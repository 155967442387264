import React, { useState } from "react";
import * as C from "./style";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import img from "../../../../assets/LandingPageParceiro/imgCadastroParceiro.svg";
import arquivoVazio from "../../../../assets/LandingPageParceiro/Icones/arquivoVazio.svg";
import arquivoAnexado from "../../../../assets/LandingPageParceiro/Icones/arquivoAnexado.svg";
import { MenuItem, Stack } from "@mui/material";

const CadastrarParceiro: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);

  const handleStep = (handle: "+" | "-") => {
    if (handle === "+") {
      setStep(step + 1);
    } else {
      setStep(step - 1);
    }
  };

  return (
    <C.Container>
      <C.BackButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </C.BackButton>
      <C.LeftSide>
        <C.LeftContent>
          <C.TitleArea>
            <h1>
              Bem-vindo! Estamos felizes em tê-lo conosco. Vamos começar juntos
              essa jornada de sucesso!
            </h1>
          </C.TitleArea>
          <C.TopicsArea>
            <Stack direction={"row"} spacing={2}>
              <C.CheckboxWrapper>
                <C.TopicsCheckbox checked={true} readOnly />
                <p>Receba suporte especializado em todas as etapas.</p>
              </C.CheckboxWrapper>
              <C.CheckboxWrapper>
                <C.TopicsCheckbox checked={true} readOnly />
                <p>Transparência e segurança nas transações financeiras.</p>
              </C.CheckboxWrapper>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <C.CheckboxWrapper>
                <C.TopicsCheckbox checked={true} readOnly />
                <p>
                  Material personalizado feito por especialistas em marketing.
                </p>
              </C.CheckboxWrapper>
              <C.CheckboxWrapper>
                <C.TopicsCheckbox checked={true} readOnly />
                <p>
                  Uma plataforma intuitiva e segura para gerenciar seus
                  clientes.
                </p>
              </C.CheckboxWrapper>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <C.CheckboxWrapper>
                <C.TopicsCheckbox checked={true} readOnly />
                <p>
                  Total apoio do time comercial para impulsionar suas vendas.
                </p>
              </C.CheckboxWrapper>
              <C.CheckboxWrapper>
                <C.TopicsCheckbox checked={true} readOnly />
                <p>Ganhe enquanto seus clientes mantiverem contratos ativos.</p>
              </C.CheckboxWrapper>
            </Stack>
          </C.TopicsArea>
          <C.ImageArea>
            <img src={img} alt="imgColaborador" />
          </C.ImageArea>
        </C.LeftContent>
      </C.LeftSide>
      <C.RightSide>
        {step === 1 && (
          <>
            <C.TextArea>
              <C.Title>
                Juntos, podemos alcançar grandes conquistas.
                <br />
                <span>Vamos em frente!</span>
              </C.Title>
            </C.TextArea>
            <C.PaginatorArea>
              <h4>Insira suas informações pessoais.</h4>

              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                {[1, 2, 3, 4].map((item) => (
                  <C.PaginatorItem key={item} $active={step === item} />
                ))}
              </C.FWStack>
            </C.PaginatorArea>
            <C.FormArea>
              <C.FWStack direction={"row"} spacing={4} sx={{ width: "100%" }}>
                <C.CustomInput
                  type="text"
                  label="Nome completo do representante legal"
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="CPF" fullWidth />
                <C.CustomInput type="text" label="RG" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput select label="Estado civil" fullWidth>
                  <MenuItem value={undefined} sx={{ color: "gray" }}>
                    -
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Solteiro
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    Casado
                  </MenuItem>
                  <MenuItem key={3} value={3}>
                    Divorciado
                  </MenuItem>
                  <MenuItem key={4} value={4}>
                    Viúvo
                  </MenuItem>
                </C.CustomInput>
                <C.CustomInput type="text" label="Celular" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="Email" fullWidth />
                <C.CustomInput type="text" label="Profissão" fullWidth />
              </C.FWStack>
              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                <C.ContainedButton
                  variant="contained"
                  onClick={() => handleStep("+")}
                >
                  Próximo
                </C.ContainedButton>
              </C.FWStack>
            </C.FormArea>
          </>
        )}
        {step === 2 && (
          <>
            <C.TextArea>
              <C.Title>
                <span>Você está quase lá! </span> Complete seu cadastro para
                aproveitar todos os beneficios.
              </C.Title>
            </C.TextArea>
            <C.PaginatorArea>
              <h4>Insira seu endereço pessoal.</h4>

              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                {[1, 2, 3, 4].map((item) => (
                  <C.PaginatorItem key={item} $active={step === item} />
                ))}
              </C.FWStack>
            </C.PaginatorArea>
            <C.FormArea>
              <C.FWStack direction={"row"} spacing={4} sx={{ width: "100%" }}>
                <C.CustomInput type="text" label="CEP" fullWidth />
                <C.CustomInput type="text" label="Estado" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="Cidade" fullWidth />
                <C.CustomInput type="text" label="Bairro" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="Logradouro" fullWidth />
                <C.CustomInput type="text" label="Número" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="Complemento" fullWidth />
              </C.FWStack>
              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                <C.OutlineButton
                  variant="outlined"
                  onClick={() => handleStep("-")}
                >
                  Voltar
                </C.OutlineButton>
                <C.ContainedButton
                  variant="contained"
                  onClick={() => handleStep("+")}
                >
                  Próximo
                </C.ContainedButton>
              </C.FWStack>
            </C.FormArea>
          </>
        )}
        {step === 3 && (
          <>
            <C.TextArea>
              <C.Title>
                <span>Quase pronto! </span> Complete seu cadastro agora e
                aproveite tudo o que temos a oferecer.
              </C.Title>
            </C.TextArea>
            <C.PaginatorArea>
              <h4>Insira as informações da sua empresa.</h4>

              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                {[1, 2, 3, 4].map((item) => (
                  <C.PaginatorItem key={item} $active={step === item} />
                ))}
              </C.FWStack>
            </C.PaginatorArea>
            <C.FormArea>
              <C.FWStack direction={"row"} spacing={4} sx={{ width: "100%" }}>
                <C.CustomInput type="text" label="CEP" fullWidth />
                <C.CustomInput type="text" label="Estado" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="Cidade" fullWidth />
                <C.CustomInput type="text" label="Bairro" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="Logradouro" fullWidth />
                <C.CustomInput type="text" label="Número" fullWidth />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={4}>
                <C.CustomInput type="text" label="Complemento" fullWidth />
              </C.FWStack>
              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                <C.OutlineButton
                  variant="outlined"
                  onClick={() => handleStep("-")}
                >
                  Voltar
                </C.OutlineButton>
                <C.ContainedButton
                  variant="contained"
                  onClick={() => handleStep("+")}
                >
                  Próximo
                </C.ContainedButton>
              </C.FWStack>
            </C.FormArea>
          </>
        )}
        {step === 4 && (
          <>
            <C.TextArea>
              <C.Title>
                <span>Só falta mais um passo! </span> Complete seu cadastro e
                comece a explorar.
              </C.Title>
            </C.TextArea>
            <C.PaginatorArea>
              <h4>Agora só falta anexar seus documentos</h4>

              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                {[1, 2, 3, 4].map((item) => (
                  <C.PaginatorItem key={item} $active={step === item} />
                ))}
              </C.FWStack>
            </C.PaginatorArea>
            <C.FormArea>
              <C.ArchivesArea>
                <C.ArchiveContainer>
                  <C.Archive src={arquivoAnexado} />
                  <h4>RG ou CNH</h4>
                  <p>Documento anexado</p>
                </C.ArchiveContainer>
                <C.ArchiveContainer>
                  <C.Archive src={arquivoVazio} />
                  <h4>Contrato social</h4>
                  <p>Anexar documento</p>
                </C.ArchiveContainer>
                <C.ArchiveContainer>
                  <C.Archive src={arquivoVazio} />
                  <h4>Cartão CNPJ</h4>
                  <p>Anexar documento</p>
                </C.ArchiveContainer>
              </C.ArchivesArea>
              <C.FWStack
                direction={"row"}
                spacing={4}
                justifyContent={"center"}
              >
                <C.OutlineButton
                  variant="outlined"
                  onClick={() => handleStep("-")}
                >
                  Voltar
                </C.OutlineButton>
                <C.ContainedButton variant="contained">
                  Cadastrar
                </C.ContainedButton>
              </C.FWStack>
            </C.FormArea>
          </>
        )}
      </C.RightSide>
    </C.Container>
  );
};

export default CadastrarParceiro;
