import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as G from "../../style";
import { Checkbox, Stack, TextField } from "@mui/material";
import * as D from "../../../../../../../styles/appComponents";
import { EnumModalidadePlano } from "../../../../../../../enums/EnumModalidadePlano.enum";
import { EnumTipoComissao } from "../../../../../../../enums/EnumTipoComissao.enum";

const BeneficiosComissao: React.FC = () => {
  const [planoSaude, setPlanoSaude] = useState(false);
  const [planoOdontologico, setPlanoOdontologico] = useState(false);
  const [vr, setVr] = useState(false);
  const [va, setVa] = useState(false);
  const [batePonto, setBatePonto] = useState("");
  const [recebeComissao, setRecebeComissao] = useState("");
  const [selectedValueModPlanoSaude, setSelectedValueModPlanoSaude] =
    useState("");
  const [selectedValueModPlanoOdonto, setSelectedValueModPlanoOdonto] =
    useState("");
  const [selectedValueComissao, setSelectedValueComissao] = useState("");

  const handleChangeSelectComissao = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueComissao(event.target.value);
  };

  const handleChangeSelectBatePonto = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBatePonto(event.target.value);
  };

  const handleChangeSelectRecebeComissao = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRecebeComissao(event.target.value);
  };

  const handleChangeSelectModPlanoSaude = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueModPlanoSaude(event.target.value);
  };

  const handleChangeSelectModPlanoOdonto = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedValueModPlanoOdonto(event.target.value);
  };

  return (
    <RegisterCard title="Benefícios/comissão">
      <G.FWStack direction={"row"} spacing={8}>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Planos</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              checked={planoSaude}
              onChange={() => setPlanoSaude(!planoSaude)}
            />
            <p>Plano de saúde</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              checked={planoOdontologico}
              onChange={() => setPlanoOdontologico(!planoOdontologico)}
            />
            <p>Plano odontológico</p>
          </G.CheckboxWrapper>
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Modalidade plano de saúde</G.Title>
          {Object.values(EnumModalidadePlano).map((value) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={!planoSaude}
                checked={selectedValueModPlanoSaude === value}
                value={value}
                onChange={(e) => handleChangeSelectModPlanoSaude(e)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Modalidade plano odontológico</G.Title>
          {Object.values(EnumModalidadePlano).map((value) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                disabled={!planoOdontologico}
                checked={selectedValueModPlanoOdonto === value}
                value={value}
                onChange={(e) => handleChangeSelectModPlanoOdonto(e)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Bate ponto elêtronico</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              checked={batePonto === "sim"}
              value={"sim"}
              onChange={(e) => handleChangeSelectBatePonto(e)}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              checked={batePonto === "nao"}
              value={"nao"}
              onChange={(e) => handleChangeSelectBatePonto(e)}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </G.FWStack>
        <G.FWStack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Recebe comissão</G.Title>
          <G.CheckboxWrapper>
            <Checkbox
              checked={recebeComissao === "sim"}
              value={"sim"}
              onChange={(e) => handleChangeSelectRecebeComissao(e)}
            />
            <p>Sim</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox
              checked={recebeComissao === "nao"}
              value={"nao"}
              onChange={(e) => handleChangeSelectRecebeComissao(e)}
            />
            <p>Não</p>
          </G.CheckboxWrapper>
        </G.FWStack>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2} alignItems={"flex-end"}>
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Comissão</G.Title>
          {Object.values(EnumTipoComissao).map((value) => (
            <G.CheckboxWrapper key={value}>
              <Checkbox
                checked={selectedValueComissao === value}
                value={value}
                onChange={(e) => handleChangeSelectComissao(e)}
              />
              <p>{value}</p>
            </G.CheckboxWrapper>
          ))}
        </Stack>

        <TextField
          fullWidth
          label="Porcentual de comissão"
          disabled={selectedValueComissao.toLowerCase() !== "porcentagem"}
        />
        <TextField
          fullWidth
          label="Valor fixo de comissão"
          disabled={selectedValueComissao.toLowerCase() !== "fixa"}
        />
        <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
          <G.Title>Recebe</G.Title>
          <G.CheckboxWrapper>
            <Checkbox checked={vr} onChange={() => setVr(!vr)} />
            <p>VR</p>
          </G.CheckboxWrapper>
          <G.CheckboxWrapper>
            <Checkbox checked={va} onChange={() => setVa(!va)} />
            <p>VA</p>
          </G.CheckboxWrapper>
        </Stack>

        <TextField fullWidth label="Valor dia VR" disabled={!vr} />
        <TextField fullWidth label="Valor dia VA" disabled={!va} />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton $color="yellow">Editar</D.ContainedButton>
        <D.ContainedButton>Salvar</D.ContainedButton>
      </G.FWStack>
    </RegisterCard>
  );
};

export default BeneficiosComissao;
