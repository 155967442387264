import RegisterCard from "../../../../../../../components/RegisterCard";
import React, { useEffect, useState } from "react";
import * as G from "../../style";
import { Checkbox, Chip, MenuItem, Stack, TextField } from "@mui/material";
import { ptBR } from "@mui/x-data-grid/locales";
import * as D from "../../../../../../../styles/appComponents";
import { GridColDef } from "@mui/x-data-grid";

const CRM: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 1,
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
    },
  ];

  const rows = [
    {
      id: 1,
      responsavel: "Thaynara",
      descricao: "Anexou atestado",
      data: "03/11/2023",
    },
    {
      id: 2,
      responsavel: "Thaynara",
      descricao: "Anexou atestado",
      data: "03/11/2023",
    },
    {
      id: 3,
      responsavel: "Thaynara",
      descricao: "Anexou atestado",
      data: "03/11/2023",
    },
    {
      id: 4,
      responsavel: "Thaynara",
      descricao: "Anexou atestado",
      data: "03/11/2023",
    },
    {
      id: 5,
      responsavel: "Thaynara",
      descricao: "Anexou atestado",
      data: "03/11/2023",
    },
    {
      id: 6,
      responsavel: "Thaynara",
      descricao: "Anexou atestado",
      data: "03/11/2023",
    },
  ];
  return (
    <RegisterCard title="CRM">
      <D.DefaultTable
        disableColumnResize
        disableRowSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
      />
    </RegisterCard>
  );
};

export default CRM;
