import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";

const Colaborador: React.FC = () => {
  const [page, setPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const navigate = useNavigate();
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);

  const handleChange = (value: number) => {
    setPage(value);
  };
  const handleChange2 = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenModalSeeMore = () => {
    setOpenModalSeeMore(true);
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "document",
      headerName: "CPF",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "telefone", headerName: "Telefone", flex: 1 },
    { field: "funcao", headerName: "Função", flex: 1 },
    {
      field: "situacao",
      headerName: "Situação",
      flex: 1,
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpenModalSeeMore()}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = [
    {
      id: 0,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 1,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 2,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 3,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 4,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 5,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 6,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 7,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 8,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
    {
      id: 9,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      funcao: "Backoffice",
      situacao: "Ativo",
      outras: "ver mais",
    },
  ];

  const columnsModalDocument: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "assunto", headerName: "Assunto", flex: 1 },
    { field: "data", headerName: "Data Upload", flex: 1 },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDownloadButton
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            Baixar
          </C.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
  ];

  const rowsModalDocument = [
    {
      id: 1,
      assunto: "RG",
      data: "05/11/2013",
      anexo: 0,
    },
    {
      id: 2,
      assunto: "CPF",
      data: "05/11/2013",
      anexo: 0,
    },
    {
      id: 3,
      assunto: "Carteira de trabalho",
      data: "05/11/2013",
      anexo: 0,
    },
    {
      id: 4,
      assunto: "Atestado",
      data: "05/11/2013",
      anexo: 0,
    },
  ];

  return (
    <>
      <C.Container>
        <D.DataArea $align="right">
          <C.HeaderTable>
            <D.ContainedButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate("/Cadastro/Colaborador")}
            >
              Novo colaborador
            </D.ContainedButton>
            <D.DefaultSearchInput
              size="small"
              variant="outlined"
              onChange={() => null}
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </C.HeaderTable>
          {!isMobile ? (
            // <Datatable
            //   columns={columns}
            //   rows={rows}
            //   pageNumber={page}
            //   totalPages={5}
            //   onChangePage={(e) => handleChange(e)}
            // />
            <></>
          ) : (
            <C.CardDataAreaMobile>
              {rows.map((row) => (
                <>
                  <C.CardDataMobile key={row.id}>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Id:</label>
                        <p>{row.id}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile>
                        <label>Nome:</label>
                        <p>{row.nome}</p>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>CPF ou CNPJ:</label>
                        <p>{row.document}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile>
                        <label>Função:</label>
                        <p>{row.funcao}</p>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                    <C.CardDataLineMobile>
                      <C.CardDataItemMobile>
                        <label>Telefone:</label>
                        <p>{row.telefone}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile>
                        <label>Situação:</label>
                        <p>{row.situacao}</p>
                      </C.CardDataItemMobile>
                      <C.CardDataItemMobile $center={true}>
                        <D.DefaultGridButton onClick={() => {}}>
                          Ver mais
                        </D.DefaultGridButton>
                      </C.CardDataItemMobile>
                    </C.CardDataLineMobile>
                  </C.CardDataMobile>
                  <D.DefaultPagination
                    count={10}
                    size={"small"}
                    siblingCount={0}
                    showFirstButton
                    showLastButton
                    onChange={handleChange2}
                    color="primary"
                  />
                </>
              ))}
            </C.CardDataAreaMobile>
          )}

          <C.LineBreak />
          <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status de leads</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%" }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Colaborador - 9591</h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Nome:</label>
                  <p>Raiza Estefania de andrade</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Data da conexão:</label>
                  <p>00/00/0000</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>CPF:</label>
                  <p>00000000000</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>RG:</label>
                  <p>0000000000</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Data de nascimento:</label>
                  <p>00/00/0000</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Data de admissão:</label>
                  <p>00/00/0000</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Documentos/exames</h2>
              </C.ModalHeader>
              {/* <Datatable
                columns={columnsModalDocument}
                rows={rowsModalDocument}
                pageNumber={1}
                onChangePage={() => {}}
                totalPages={3}
              /> */}
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default Colaborador;
