import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/public/Home";
import LoginLayout from "../layouts/loginLayout";
import LoginParceiro from "../pages/public/Parceiro/LoginParceiro";
import LoginCliente from "../pages/public/Cliente/LoginCliente";
import LoginColaborador from "../pages/public/Colaborador/LoginColaborador";
import Leads from "../pages/private/Colaborador/Leads";
import Dashboard from "../pages/private/Colaborador/Dashboard";
import RecuperarSenhaCliente from "../pages/public/Cliente/RecuperarSenhaCliente";
import RecuperarSenhaColaborador from "../pages/public/Colaborador/RecuperarSenhaColaborador";
import RecuperarSenhaParceiro from "../pages/public/Parceiro/RecuperarSenhaParceiro";
import Proposals from "../pages/private/Colaborador/Proposals";
import Contracts from "../pages/private/Colaborador/Contracts";
import LoginGerador from "../pages/public/Gerador/LoginGerador";
import RecuperarSenhaGerador from "../pages/public/Gerador/RecuperarSenhaGerador";
import ParceiroHome from "../pages/public/Parceiro/Home";
import CadastrarParceiro from "../pages/public/Parceiro/CadastrarParceiro";
import ColaboradorLayout from "../layouts/colaboradorLayout";
import Parceiro from "../pages/private/Colaborador/Parceiro";
import Gerador from "../pages/private/Colaborador/Gerador";
import Cliente from "../pages/private/Colaborador/Cliente";
import CadastroCliente from "../pages/private/Colaborador/Cliente/Cadastro";
import LayoutNav from "../layouts/layoutNav";
import CadastroParceiro from "../pages/private/Colaborador/Parceiro/Cadastro";
import CadastroGerador from "../pages/private/Colaborador/Gerador/Cadastro";
import CadastroColaborador from "../pages/private/Colaborador/Colaborador/Cadastro";
import Colaborador from "../pages/private/Colaborador/Colaborador";
import { AuthProvider } from "../hooks/useAuth";
import ParceiroLayout from "../layouts/parceiroLayout";
import ParceiroLeads from "../pages/private/Parceiro/Leads";
import ParceiroPropostas from "../pages/private/Parceiro/Propostas";
import ParceiroContratos from "../pages/private/Parceiro/Contratos";
import ParceiroDashboard from "../pages/private/Parceiro/Dashboard";
import MeuCadastroParceiro from "../pages/private/Parceiro/MeuCadastro";
import GeraLead from "../pages/public/Leads/GeraLead";
import AnexarArquivo from "../pages/public/Leads/AnexarArquivo";
import ParceiroAssociados from "../pages/private/Parceiro/Associados";

const AppRoutes: React.FC = () => (
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" index element={<Home />} />
        <Route path="/Convida/:url" index element={<GeraLead />} />
        <Route path="/AnexarConta/:id" index element={<AnexarArquivo />} />
        <Route path="/Login" element={<LoginLayout />}>
          <Route path="/Login" element={<Navigate to="/" />} />
          <Route path="/Login/Parceiro" element={<LoginParceiro />} />
          <Route
            path="/Login/Parceiro/RecuperarSenha"
            index
            element={<RecuperarSenhaParceiro />}
          />
          <Route path="/Login/Cliente" element={<LoginCliente />} />
          <Route
            path="/Login/Cliente/RecuperarSenha"
            element={<RecuperarSenhaCliente />}
          />
          <Route path="/Login/Colaborador" element={<LoginColaborador />} />
          <Route
            path="/Login/Colaborador/RecuperarSenha"
            element={<RecuperarSenhaColaborador />}
          />
          <Route path="/Login/Gerador" element={<LoginGerador />} />
          <Route
            path="/Login/Gerador/RecuperarSenha"
            index
            element={<RecuperarSenhaGerador />}
          />
        </Route>

        <Route
          path="/Parceiro/CadastrarParceiro"
          element={<CadastrarParceiro />}
        />

        {/* Colaborador */}
        <Route path="/Colaborador" element={<ColaboradorLayout />}>
          <Route path="/Colaborador" element={<Navigate to="/" />} />
          <Route path="/Colaborador/Dashboard" element={<Dashboard />} />
          <Route path="/Colaborador/Comercial/GD/Lead" element={<Leads />} />
          <Route
            path="/Colaborador/Comercial/GD/Proposta"
            element={<Proposals />}
          />
          <Route
            path="/Colaborador/Comercial/GD/ContratoCliente"
            element={<Contracts />}
          />
          <Route
            path="/Colaborador/Cadastro/GD/Parceiro"
            element={<Parceiro />}
          />
          <Route
            path="/Colaborador/Cadastro/GD/Colaborador"
            element={<Colaborador />}
          />
          <Route
            path="/Colaborador/Cadastro/GD/Cliente"
            element={<Cliente />}
          />
          <Route
            path="/Colaborador/Cadastro/GD/Gerador"
            element={<Gerador />}
          />
        </Route>
        <Route path="/Cadastro" element={<LayoutNav />}>
          <Route path="/Cadastro" element={<Navigate to="/" />} />
          <Route path="/Cadastro/Cliente" element={<CadastroCliente />} />
          <Route path="/Cadastro/Parceiro" element={<CadastroParceiro />} />
          <Route path="/Cadastro/Gerador" element={<CadastroGerador />} />
          <Route
            path="/Cadastro/Colaborador"
            element={<CadastroColaborador />}
          />
        </Route>
        {/* End Colaborador */}
        {/*Parceiro*/}
        <Route path="/Parceiro" element={<ParceiroHome />} />
        <Route path="/Parceiro" element={<ParceiroLayout />}>
          <Route path="/Parceiro/Dashboard" element={<ParceiroDashboard />} />
          <Route path="/Parceiro/Leads" element={<ParceiroLeads />} />
          <Route path="/Parceiro/Propostas" element={<ParceiroPropostas />} />
          <Route path="/Parceiro/Contratos" element={<ParceiroContratos />} />
          <Route path="/Parceiro/Associados" element={<ParceiroAssociados />} />
          <Route path="/Parceiro/Comissao" element={<ParceiroDashboard />} />
          <Route
            path="/Parceiro/MeuCadastro"
            element={<MeuCadastroParceiro />}
          />
        </Route>
        {/*End Parceiro*/}
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

export default AppRoutes;
