import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PublishIcon from "@mui/icons-material/Publish";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import ContractIcon from "../../../../assets/Plataforma/contractIcon.svg";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import FolderIcon from "../../../../assets/Plataforma/folderIcon.svg";
import PourposeIcon from "../../../../assets/Plataforma/pourposeIcon.svg";
import CircleCheckbox from "../../../../components/CircleCheckBox";
import StatusCard from "../../../../components/StatusCard";
import TotalCard from "../../../../components/TotalCard";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";

const Proposals: React.FC = () => {
  const [page, setPage] = useState(1);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [openModalDocuments, setOpenModalDocuments] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedId, setSelectedId] = React.useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModalSeeMore = () => {
    setOpenModalSeeMore(true);
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleOpenModalDocuments = () => {
    setOpenModalDocuments(true);
  };

  const handleCloseModalDocuments = () => {
    setOpenModalDocuments(false);
  };

  const handleSelect = (id: number) => {
    setSelectedId(id);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "cliente",
      headerName: "Cliente",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "instalacao", headerName: "Instalação", flex: 1 },
    {
      field: "parceiro",
      headerName: "Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    {
      field: "documentos",
      headerName: "Documentos",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.GridImage
            src={FolderIcon}
            alt="folderIcon"
            onClick={() => handleOpenModalDocuments()}
          />
        </D.GridField>
      ),
    },
    {
      field: "proposta",
      headerName: "Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.GridImage $disable src={PourposeIcon} alt="pourposeIcon" />
        </D.GridField>
      ),
    },
    {
      field: "contratar",
      headerName: "Contratar",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.GridImage $disable src={ContractIcon} alt="contractIcon" />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpenModalSeeMore()}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = [
    {
      id: 0,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 1,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 2,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 3,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 4,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 5,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 6,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 7,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 8,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
    {
      id: 9,
      cliente: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      status: 0,
      documentos: 0,
      proposta: 0,
      contratar: 0,
      outras: "ver mais",
    },
  ];

  const columnsModal: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "responsavel", headerName: "Responsavel", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data", flex: 1 },
  ];

  const rowsModal = [
    {
      id: 1,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 2,
      responsavel: "Rebecca de Assis",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 3,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 4,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 1,
      data: "05/11/2013",
    },
    {
      id: 5,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 1,
      data: "05/11/2013",
    },
  ];

  const columnsModalDocument: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    { field: "data", headerName: "Data Upload", flex: 1 },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDownloadButton
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            Baixar
          </C.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDeleteButton
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Deletar
          </C.ModalGridDeleteButton>
        </D.GridField>
      ),
    },
  ];

  const rowsModalDocument = [
    {
      id: 1,
      descricao: "Conta Cemig",
      data: "05/11/2013",
      anexo: 0,
      remover: 0,
    },
    {
      id: 2,
      descricao: "Documento Pessoal",
      data: "05/11/2013",
      anexo: 0,
      remover: 0,
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard
                color="yellow"
                text="Não teve interesse"
                number={254}
              />
              <TotalCard
                color="yellow"
                title="Valor total das propostas"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="yellow"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="blue" text="Em análise" number={56} />
              <TotalCard
                color="blue"
                title="Valor total das propostas"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="blue"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="green" text="Aprovado" number={103} />
              <TotalCard
                color="green"
                title="Valor total das propostas"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="green"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="red" text="Reprovado" number={39} />
              <TotalCard
                color="red"
                title="Valor total das propostas"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="red"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="purple" text="Cancelado" number={39} />
              <TotalCard
                color="purple"
                title="Valor total das propostas"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="purple"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="-8%"
                evolution={true}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}

        <D.DataArea $align="right">
          <D.DefaultSearchInput
            size="small"
            variant="outlined"
            onChange={() => null}
            placeholder="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {!isMobile ? (
            <D.DefaultTable
              sx={{
                border: "none",
                width: "100%",
              }}
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              columns={columns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          ) : (
            <C.CardDataAreaMobile>
              {rows.map((row) => (
                <C.CardDataMobile key={row.id}>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Id:</label>
                      <p>{row.id}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile></C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Status:</label>
                      <D.Circle color={"green"} />
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Cliente:</label>
                      <p>{row.cliente}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Parceiro:</label>
                      <p>{row.parceiro}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Instalação:</label>
                      <p>{row.instalacao}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Contratar:</label>
                      <D.GridImage src={ContractIcon} alt="contractIcon" />
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Documentos:</label>
                      <D.GridImage
                        onClick={() => handleOpenModalDocuments()}
                        src={FolderIcon}
                        alt="folderIcon"
                      />
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Propostas:</label>
                      <D.GridImage src={PourposeIcon} alt="pourposeIcon" />
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label></label>
                      <D.DefaultGridButton
                        onClick={() => handleOpenModalSeeMore()}
                      >
                        Ver mais
                      </D.DefaultGridButton>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                </C.CardDataMobile>
              ))}
            </C.CardDataAreaMobile>
          )}

          <D.DefaultPagination
            count={10}
            size={isMobile ? "small" : "medium"}
            siblingCount={isMobile ? 0 : 1}
            showFirstButton
            showLastButton
            onChange={handleChange}
            color="primary"
          />
          <C.LineBreak />
          <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status de propostas</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%" }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Paulo Machado - 9591</h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <h4>Status atual:</h4>
              <C.ModalStatusArea>
                <CircleCheckbox
                  id={1}
                  label="Não teve interesse"
                  selected={selectedId === 1}
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={0}
                  label="Em análise"
                  selected={selectedId === 0}
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={2}
                  label="Aprovado"
                  selected={selectedId === 2}
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={3}
                  label="Reprovado"
                  selected={selectedId === 3}
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={4}
                  label="Cancelado"
                  selected={selectedId === 4}
                  onSelect={handleSelect}
                />
              </C.ModalStatusArea>
              <C.ModalInputsArea>
                <C.ModalInput label="Nome" defaultValue="João Pé de Feijão" />
                <C.ModalInput label="Telefone" defaultValue="(31) 99878-1267" />
                <C.ModalInput
                  label="Email"
                  defaultValue="joaopedefeijao@gmail.com"
                />
                <C.ModalInput label="Parceiro" defaultValue="Tramonte" />
                <C.ModalInput label="Backoffice" defaultValue="Rebeca" />
                <C.ModalInput
                  label="Data"
                  type="date"
                  defaultValue="2024-01-01"
                />
              </C.ModalInputsArea>
              <C.ModalButtonsArea>
                <C.ModalHistoricButton variant="contained">
                  Incluir ao histórico
                </C.ModalHistoricButton>
                <C.ModalSaveButton variant="contained">
                  Salvar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <C.ModalCard>
              CRM
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModal}
                columns={columnsModal}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
              <C.ModalPaginationArea>
                <D.DefaultPagination
                  count={10}
                  size={isMobile ? "small" : "medium"}
                  siblingCount={isMobile ? 0 : 1}
                  showFirstButton
                  showLastButton
                  onChange={handleChange}
                  color="primary"
                />
              </C.ModalPaginationArea>
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
      <Modal
        sx={{ width: "100%" }}
        open={openModalDocuments}
        onClose={handleCloseModalDocuments}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Proposta - 9591</h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalDocuments}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Cliente:</label>
                  <p>João Carlos Cunha Melo</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Status atual:</label>
                  <C.ModalTextIcon>
                    <D.Circle color={"green"} />
                    <p>Contrato assinado</p>
                  </C.ModalTextIcon>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Captador:</label>
                  <p>GCM energia solar</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  <PublishIcon /> Upload de Arquivos
                </h2>
              </C.ModalHeader>
              <TextField
                label="Descrição do documento da proposta:"
                fullWidth
                multiline
                rows={5}
              />
              <C.ModalButtonsArea>
                <C.ModalDocumentButton
                  variant="contained"
                  startIcon={<PublishIcon />}
                >
                  Anexar Documento
                </C.ModalDocumentButton>
                <C.ModalSaveButton variant="contained">
                  Anexar
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Download anexo proposta</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModalDocument}
                columns={columnsModalDocument}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: rowsModalDocument.length,
                    },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default Proposals;
