import React, { useEffect, useState } from "react";
import * as C from "./style";
import { Stack } from "@mui/material";
import InstagramIcon from "../../../../../assets/LandingPage/instaIco.svg";
import WhatsAppIcon from "../../../../../assets/LandingPage/wppIco.svg";
import EmailIcon from "../../../../../assets/LandingPage/mailIco.svg";

interface FooterProps {
  contatoRef: React.RefObject<HTMLInputElement>;
}

const Footer: React.FC<FooterProps> = ({ contatoRef }) => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log(name, company, email, cellphone, description);
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <C.FooterWrapper ref={contatoRef}>
      <C.TextArea>
        <h1>Deseja conversar com um de nossos atendentes?</h1>
      </C.TextArea>
      <C.MediaArea>
        <p>Escolha um canal de comunicação e você será direcionado.</p>
        <C.MediaButtonsArea>
          <C.MediaLink href="#" target="_blank" rel="noopener noreferrer">
            <C.MediaButton
              variant="contained"
              startIcon={
                isMobile ? null : <img src={WhatsAppIcon} alt="whatsappIcon" />
              }
            >
              {!isMobile ? (
                <>WhatsApp</>
              ) : (
                <img src={WhatsAppIcon} alt="whatsappIcon" />
              )}
            </C.MediaButton>
          </C.MediaLink>
          <C.MediaLink
            href="mailto:paula@wattwise.app.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <C.MediaButton
              variant="contained"
              startIcon={
                isMobile ? null : <img src={EmailIcon} alt="emailIcon" />
              }
            >
              {!isMobile ? <>Email</> : <img src={EmailIcon} alt="emailIcon" />}
            </C.MediaButton>
          </C.MediaLink>
          <C.MediaLink
            href="https://www.instagram.com/wattwise.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <C.MediaButton
              variant="contained"
              startIcon={
                isMobile ? null : (
                  <img src={InstagramIcon} alt="instagramIcon" />
                )
              }
            >
              {!isMobile ? (
                <>Instagram</>
              ) : (
                <img src={InstagramIcon} alt="instagramIcon" />
              )}
            </C.MediaButton>
          </C.MediaLink>
        </C.MediaButtonsArea>
      </C.MediaArea>
      <C.FormArea onSubmit={handleSubmit}>
        <C.FormTextArea>
          <p>Caso prefira, entraremos em contato.</p>
        </C.FormTextArea>
        <Stack
          style={{ width: "100%" }}
          spacing={2}
          direction={isMobile ? "column" : "row"}
        >
          <C.FormInput
            type="text"
            variant="filled"
            label="Nome"
            onChange={(e) => setName(e.target.value)}
            value={name}
            fullWidth
            required
          />
          <C.FormInput
            type="text"
            variant="filled"
            label="Empresa"
            onChange={(e) => setCompany(e.target.value)}
            value={company}
            fullWidth
            required
          />
        </Stack>
        <Stack
          style={{ width: "100%" }}
          spacing={2}
          direction={isMobile ? "column" : "row"}
        >
          <C.FormInput
            type="text"
            variant="filled"
            label="Telefone"
            onChange={(e) => setCellphone(e.target.value)}
            value={cellphone}
            fullWidth
            required
          />
          <C.FormInput
            type="text"
            variant="filled"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
            required
          />
        </Stack>
        <C.FormInput
          type="text"
          variant="filled"
          label="Assunto"
          multiline
          rows={5}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          fullWidth
          required
        />
        <C.FormButton variant="contained" type="submit">
          ENVIAR
        </C.FormButton>
      </C.FormArea>
    </C.FooterWrapper>
  );
};

export default Footer;
