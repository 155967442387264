import React from 'react';
import { Outlet } from 'react-router-dom';
import * as C from './style'

const LoginLayout: React.FC = () => {
    return (
        <C.Container>
            <Outlet />
        </C.Container>
    );
};

export default LoginLayout;