import * as C from "./style";
import gif from "../../../../../../assets/LandingPageParceiro/bannerParceiro.gif";

const Banner = () => {
  return (
    <C.Container>
      <C.Top>
        <C.Left>
          <h3>
            Descubra novas oportunidades para o seu negócio ao se tornar um
            parceiro Watt Wise!
          </h3>
          <p>
            Oferecemos uma plataforma inteligente e intuitiva para o seu máximo
            conforto, juntamente com material personalizado feito por nossa
            equipe de marketing e total apoio do nosso time comercial. Ganhe uma
            renda extra sem nenhum custo adicional. É a hora de impulsionar o
            seu sucesso conosco!
          </p>
          <C.BannerButton>Quero ser parceiro</C.BannerButton>
        </C.Left>
        <C.Right>
          <img src={gif} alt="bannerGif" />
        </C.Right>
      </C.Top>
      <C.Bottom>
        <a>Equipe Suporte</a>
        <a>Equipe Marketing</a>
        <a>Equipe Comercial</a>
        <a>Equipe Financeiro</a>
        <a>Equipe T.I.</a>
      </C.Bottom>
    </C.Container>
  );
};

export default Banner;
