import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #fac9cf;
`;

export const Header = styled.div`
  background-color: #f36a7b;
  padding: 20px;
  width: 100%;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  h2 {
    font-size: 24px;
    margin: 0;
    color: #ffffff;
    font-weight: 500;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  padding: 30px;
  gap: 20px;
`;
