import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

export const Title = styled.h2`
  color: #3e3e3e;
  font-size: 26px;
  font-weight: 600;
  margin: 0;
`;

export const MarkedText = styled.span`
  color: #f36a7b;
`;

export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    font-size: 20px;
    color: #3e3e3e;
  }
`;

export const Right = styled.div`
  img {
    width: 500px;
  }
`;

export const AdjectivesWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

export const Adjectives = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 3px solid #f36a7b;
  gap: 8px;
  flex: 1;
  color: #3e3e3e;
  padding-left: 10px;

  h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
`;
