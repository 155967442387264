import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, Modal, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import StatusCard from "../../../../components/StatusCard";
import TotalCard from "../../../../components/TotalCard";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";

const ParceiroContratos: React.FC = () => {
  const [page, setPage] = useState(1);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleOpenModalSeeMore = () => {
    setOpenModalSeeMore(true);
  };

  const handleCloseModalSeeMore = () => {
    setOpenModalSeeMore(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "titular",
      headerName: "Titular",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "parceiro", headerName: "Parceiro", flex: 1 },
    { field: "data", headerName: "Data", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={"green"} />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpenModalSeeMore()}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = [
    {
      id: 9594,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
    {
      id: 9595,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 1,
      outras: "ver mais",
    },
    {
      id: 9596,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 2,
      outras: "ver mais",
    },
    {
      id: 9597,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 3,
      outras: "ver mais",
    },
    {
      id: 9598,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
    {
      id: 9599,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 1,
      outras: "ver mais",
    },
    {
      id: 9590,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 2,
      outras: "ver mais",
    },
    {
      id: 9591,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 3,
      outras: "ver mais",
    },
    {
      id: 9592,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
    {
      id: 9593,
      titular: "José Luiz Almeida",
      instalacao: "3000382444",
      parceiro: "Agro São Sebastião",
      data: "05/11/2013",
      status: 0,
      outras: "ver mais",
    },
  ];

  const columnsModal: GridColDef[] = [
    { field: "id", headerName: "ID" },
    { field: "responsavel", headerName: "Responsavel", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.Circle color={params.value} />
        </D.GridField>
      ),
    },
    { field: "data", headerName: "Data" },
  ];

  const rowsModal = [
    {
      id: 1,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 2,
      responsavel: "Rebecca de Assis",
      descricao: "Anexou conta",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 3,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 2,
      data: "05/11/2013",
    },
    {
      id: 4,
      responsavel: "Cliente",
      descricao: "Nova lead",
      status: 1,
      data: "05/11/2013",
    },
    {
      id: 5,
      responsavel: "Cliente",
      descricao: "Anexou conta",
      status: 1,
      data: "05/11/2013",
    },
  ];

  const columnsModalDocument: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "descricao", headerName: "Descrição", flex: 1 },
    { field: "data", headerName: "Data Upload", flex: 1 },
    {
      field: "anexo",
      headerName: "Anexo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <C.ModalGridDownloadButton
            startIcon={<DownloadIcon />}
            variant="outlined"
          >
            Baixar
          </C.ModalGridDownloadButton>
        </D.GridField>
      ),
    },
  ];

  const rowsModalDocument = [
    {
      id: 1,
      descricao: "Conta Cemig",
      data: "05/11/2013",
      anexo: 0,
    },
    {
      id: 2,
      descricao: "Documento Pessoal",
      data: "05/11/2013",
      anexo: 0,
    },
  ];

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <StatusCard
                color="yellow"
                text="Aguardando assinatura"
                number={254}
              />
              <TotalCard
                color="yellow"
                title="Valor total de contratos"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="yellow"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="blue" text="Contrato assinado" number={56} />
              <TotalCard
                color="blue"
                title="Valor total de contratos"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="blue"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="green"
                text="Conectado a operadora"
                number={103}
              />
              <TotalCard
                color="green"
                title="Valor total de contratos"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="green"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard color="red" text="Inadimplente" number={39} />
              <TotalCard
                color="red"
                title="Valor total de contratos"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="red"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <StatusCard
                color="purple"
                text="Desconexão solicitada"
                number={39}
              />
              <TotalCard
                color="purple"
                title="Valor total de contratos"
                text="R$: 200.500,00"
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="purple"
                title="Valor total de kwh"
                text="594,000 kwh"
                evolutionNumber="+8%"
                evolution={true}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}

        <D.DataArea $align="right">
          <D.DefaultSearchInput
            size="small"
            variant="outlined"
            onChange={() => null}
            placeholder="Pesquisar"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {!isMobile ? (
            <D.DefaultTable
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              columns={columns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          ) : (
            <C.CardDataAreaMobile>
              {rows.map((row) => (
                <C.CardDataMobile key={row.id}>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Id:</label>
                      <p>{row.id}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile></C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <label>Status:</label>
                      <D.Circle color={"green"} />
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Titular:</label>
                      <p>{row.titular}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Parceiro:</label>
                      <p>{row.parceiro}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Instalação:</label>
                      <p>{row.instalacao}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Data:</label>
                      <p>{row.data}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <D.DefaultGridButton
                        onClick={() => handleOpenModalSeeMore()}
                      >
                        Ver mais
                      </D.DefaultGridButton>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                </C.CardDataMobile>
              ))}
            </C.CardDataAreaMobile>
          )}

          <D.DefaultPagination
            count={10}
            size={isMobile ? "small" : "medium"}
            siblingCount={isMobile ? 0 : 1}
            showFirstButton
            showLastButton
            onChange={handleChange}
            color="primary"
          />
          <C.LineBreak />
          {/* <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status do contrato</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton> */}
        </D.DataArea>
      </C.Container>
      <Modal
        sx={{ width: "100%" }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  Contrato - 9591
                  <C.ModalTextIcon>
                    <D.Circle color={"green"} />
                    <p>Contrato assinado</p>
                  </C.ModalTextIcon>
                </h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Total de kwh:</label>
                  <p>523,09 kwh</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Valor:</label>
                  <p>R$ 129,40</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Data de assinatura:</label>
                  <p>03/11/2023</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Backoffice:</label>
                  <p>Rebecca de Assis</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Titular:</label>
                  <p>João Carlos Cunha Melo</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>CPF:</label>
                  <p>955.125.426.41</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>(31) 974357087</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Email:</label>
                  <p>Email@email.com.br</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Parceiro:</label>
                  <p>GCM energia solar</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>CPF:</label>
                  <p>064.144.402.21</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>(31) 988010912</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Email:</label>
                  <p>Email@email.com.br</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Nome da usina:</label>
                  <p>Usina</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Instalação:</label>
                  <p>3014655778</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>Download anexo contrato</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModalDocument}
                columns={columnsModalDocument}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: rowsModalDocument.length,
                    },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
              <C.ModalButtonsArea>
                <C.ModalDownloadContractButton
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                >
                  Download contrato
                </C.ModalDownloadContractButton>
                <C.ModalBillsButton>Ver faturas</C.ModalBillsButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>CRM</h2>
              </C.ModalHeader>
              <D.DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rowsModal}
                columns={columnsModal}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
              <C.ModalPaginationArea>
                <D.DefaultPagination
                  count={10}
                  size={isMobile ? "small" : "medium"}
                  siblingCount={isMobile ? 0 : 1}
                  showFirstButton
                  showLastButton
                  onChange={handleChange}
                  color="primary"
                />
              </C.ModalPaginationArea>
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default ParceiroContratos;
