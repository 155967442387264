import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";

const Cliente: React.FC = () => {
  const [page, setPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "document",
      headerName: "CPF ou CNPJ",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "telefone", headerName: "Telefone", flex: 1 },
    { field: "data", headerName: "Data", flex: 1 },

    {
      field: "captador",
      headerName: "Captador",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => {}}>Ver mais</D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = [
    {
      id: 0,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 1,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 2,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 3,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 4,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 5,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 6,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 7,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 8,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
    {
      id: 9,
      nome: "José Luiz Almeida",
      document: "0000000000",
      telefone: "(37) 984563123",
      data: "05/11/2013",
      captador: "Agro São Sebastião",
      outras: "ver mais",
    },
  ];

  return (
    <>
      <C.Container>
        <D.DataArea $align="right">
          <C.HeaderTable>
            <D.ContainedButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate("/Cadastro/Cliente")}
            >
              Novo cliente
            </D.ContainedButton>
            <D.DefaultSearchInput
              size="small"
              variant="outlined"
              onChange={() => null}
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </C.HeaderTable>
          {!isMobile ? (
            <D.DefaultTable
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              columns={columns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
          ) : (
            <C.CardDataAreaMobile>
              {rows.map((row) => (
                <C.CardDataMobile key={row.id}>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Id:</label>
                      <p>{row.id}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Nome:</label>
                      <p>{row.nome}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>CPF ou CNPJ:</label>
                      <p>{row.document}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Captador:</label>
                      <p>{row.captador}</p>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                  <C.CardDataLineMobile>
                    <C.CardDataItemMobile>
                      <label>Telefone:</label>
                      <p>{row.telefone}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile>
                      <label>Data:</label>
                      <p>{row.data}</p>
                    </C.CardDataItemMobile>
                    <C.CardDataItemMobile $center={true}>
                      <D.DefaultGridButton onClick={() => {}}>
                        Ver mais
                      </D.DefaultGridButton>
                    </C.CardDataItemMobile>
                  </C.CardDataLineMobile>
                </C.CardDataMobile>
              ))}
            </C.CardDataAreaMobile>
          )}

          <D.DefaultPagination
            count={10}
            size={isMobile ? "small" : "medium"}
            siblingCount={isMobile ? 0 : 1}
            showFirstButton
            showLastButton
            onChange={handleChange}
            color="primary"
          />
          <C.LineBreak />
          <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status de leads</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
    </>
  );
};

export default Cliente;
