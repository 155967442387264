import styled from "styled-components";

import { Button, Checkbox, IconButton, Stack, TextField } from "@mui/material";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const BackButton = styled(IconButton)`
  position: absolute;
  margin: 30px 0 0 30px;
  background-color: white;

  svg {
    color: #f36a7b;
  }

  &:hover {
    background-color: #e6e3e3;
  }

  @media (max-width: 800px) {
    margin: 10px 0 0 10px;
  }
`;

export const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  max-height: 100%;
  background-color: #f36a7b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  h1 {
    margin: 0;
    color: #ffffff;

    @media (max-width: 1440px) {
      font-size: 20px;
    }
  }
`;

export const TopicsArea = styled.div`
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1024px) {
    gap: 18px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  p {
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;

    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
`;

export const TopicsCheckbox = styled(Checkbox)`
  && {
    color: #ffffff;
    &.Mui-checked {
      color: #ffffff;
    }
  }
`;

export const ImageArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 30%;

    @media (max-width: 1440px) {
      width: 20%;
    }
  }
`;

export const RightSide = styled.div`
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 50px;

  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
  }
`;

export const TextArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h3`
  margin: 0;
  color: #3e3e3e;
  font-size: 28px;
  font-weight: 400;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 24px;
    text-align: center;
  }

  span {
    color: #f36a7b;
  }
`;

export const PaginatorArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  h4 {
    color: #262626;
    font-family: "Roboto", "sans-serif";
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    margin: 0;
  }
`;

export const PaginatorItem = styled.div<{ $active: boolean }>`
  width: 80px;
  height: 6px;
  border-radius: 6px;
  background-color: ${(props) => (props.$active ? "#EF497A" : "#ADADAD")};
`;

export const FormArea = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const FWStack = styled(Stack)`
  width: 100%;
`;

export const CustomInput = styled(TextField)`
  width: 100%;

  & label.Mui-focused {
    color: #6b767f;
  }
  & .MuiInput-underline:after {
    border-bottom-color: green;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
    }
    &:hover fieldset {
      border-color: #6b767f;
    }
    &.Mui-focused fieldset {
      border-color: #6b767f;
    }
  }
`;

export const ContainedButton = styled(Button)`
  background-color: #f36a7b;
  color: white;
  padding: 5px 20px;

  &:hover {
    background-color: #f36a7b;
  }
`;

export const OutlineButton = styled(Button)`
  color: white;
  border-color: #f36a7b;
  color: #f36a7b;
  padding: 5px 20px;

  &:hover {
    background-color: #e3e3e3;
    border-color: #f36a7b;
  }
`;

export const LinksArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Links = styled(Link)`
  text-decoration: none;
  color: #0d1fd6;

  &:hover {
    cursor: pointer;
  }
`;

export const ArchivesArea = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 0;
`;

export const ArchiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  gap: 20px;

  h4 {
    margin: 0;
    color: #3e3e3e;
    font-size: 14px;
    font-weight: 500;
  }

  p {
    margin: 0;
    color: #3e3e3e;
    font-size: 14px;
    font-weight: 400;
  }
`;
export const Archive = styled.img`
  margin: 0;
  width: 50% !important;
`;
