import React from "react";
import Login from "../../../../components/Login";
import cliente from "../../../../assets/Login/imgCliente.svg";
import * as C from "../../../../components/Login/style";
import { useNavigate } from "react-router-dom";

const topics: string[] = [
  "Sua conexão é a nossa prioridade.",
  "Obrigado por escolher a nossa empresa. Sua presença é essencial para nós.",
  "Aproveite a nossa plataforma foi pensada especialmente para você. Entre para explorar!",
];

const RecuperarSenhaCliente: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Login
        title="cliente"
        topics={topics}
        imageSrc={cliente}
        formTitle="Recuperar Senha"
        formSubtitle="Esqueceu sua senha? digite seu e-mail que enviaremos um link para definir uma nova senha"
      >
        <C.LoginForm>
          <C.LoginInput label="Login" variant="outlined" />
          <C.LoginInput label="E-mail" variant="outlined" type="email" />
          <C.LoginButton
            variant="contained"
            onClick={() => navigate("/Login/Cliente")}
          >
            Recuperar
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default RecuperarSenhaCliente;
