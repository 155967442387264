export enum EnumStatusProposta {
  naoTeveInteresse = "Não teve interesse",
  emAnalise = "Em análise",
  aprovada = "Aprovada",
  reprovada = "Reprovada",
  cancelada = "Cancelada",
}

export const StatusPropostaNumberMap: {
  [key: number]: EnumStatusProposta;
} = {
  0: EnumStatusProposta.naoTeveInteresse,
  1: EnumStatusProposta.emAnalise,
  2: EnumStatusProposta.aprovada,
  3: EnumStatusProposta.reprovada,
  4: EnumStatusProposta.cancelada,
};

export const StatusPropostaColorMap: Record<
  number,
  "yellow" | "blue" | "green" | "red" | "purple"
> = {
  0: "yellow",
  1: "blue",
  2: "green",
  3: "red",
  4: "purple",
};
