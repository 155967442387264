import { useEffect, useState } from "react";
import { ISidebarItem } from "../../models/SidebarPopulate";
import * as C from "./style";
import { useLocation } from "react-router-dom";

const SidebarItem = (sidebarMenuItem: ISidebarItem) => {
  const [open, setOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === sidebarMenuItem.path) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location]);

  return (
    <C.SideBarMenuWrapper $open={open} to={sidebarMenuItem.path}>
      <p>{sidebarMenuItem.title}</p>
    </C.SideBarMenuWrapper>
  );
};

export default SidebarItem;
