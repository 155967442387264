import styled from "styled-components";

export const ScrollTop = styled.div` 
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e8a14d;
    bottom: 0;
    right: 0;
    z-index: 999;
    cursor: pointer;
    margin: 0 50px 30px 0;

    &:hover{
        background-color: #c7883e;
    }

    @media (max-width: 768px) {
        margin: 0 20px 30px 0;
    }
`